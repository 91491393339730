import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useCallback } from "react";

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
  branch: number;
}

interface Branch {
  id: number;
  name: string;
  phone: string;
  email: string;
  delivery_cost?: number;
  base_distance?: number;
  delivery_cost_per_additional_mile?: number;
  max_distance?: number;
  delivery_feature: boolean;
  operating_hours: OperatingHour[];
}

const Settings = () => {
  const { user } = useUser();
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<Branch>({} as Branch);
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [avatar, setAvatar] = useState<string | null>(null);
  const [isAvatarUploaded, setIsAvatarUploaded] = useState(false);
  const [avatarPreviewUrl, setAvatarPreviewUrl] = useState("");

  // Fetch branches by business name
  const fetchBranchesByBusinessName = useCallback(
    async (businessName: string) => {
      if (loading || !axiosInstance) return;

      try {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/?business_name=${businessName}`;
        const response = await axiosInstance.get(apiUrl);
        setBranches(response.data.results);
        const curBranch = response.data.results.find(
          (branch: Branch) => branch.id === user?.selectedBranch?.id,
        );
        setSelectedBranch(curBranch || ({} as Branch));
        if (curBranch) {
          setOperatingHours(curBranch.operating_hours);
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    },
    [loading, axiosInstance, user],
  );

  // Trigger fetch branches on component mount or when axiosInstance changes
  useEffect(() => {
    const storedUserData = localStorage.getItem("user_data");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    const businessName = userData?.owner?.business_name;

    if (businessName) {
      fetchBranchesByBusinessName(businessName);
    }
  }, [fetchBranchesByBusinessName]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);
      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleSendPasswordResetEmail = async () => {
    const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_CHANGEPASSWORD;
    if (!apiUrl) {
      console.error("API URL is not defined.");
      setIsError(true);
      setFeedbackMessage("Configuration error. Please try again later.");
      setShowBanner(true);
      return;
    }
    try {
      if (!loading && axiosInstance) {
        await axiosInstance.post(apiUrl, { email: user?.email });
        setIsError(false);
        setFeedbackMessage(
          "Password reset email sent successfully. Please check your inbox.",
        );
        setShowBanner(true);
      }
    } catch (error) {
      console.error("Error sending password reset email:", error);
      setIsError(true);
      setFeedbackMessage(
        "Error sending password reset email. Please try again.",
      );
      setShowBanner(true);
    }
  };

  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void,
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  const handleAvatarUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setAvatarPreviewUrl(URL.createObjectURL(file));
      setIsAvatarUploaded(true);

      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        if (typeof base64Result === "string") {
          const base64Data = base64Result.split(",")[1];
          setAvatar(base64Data);
        }
      });
    }
  };

  const handleRemoveAvatar = () => {
    setAvatarPreviewUrl("");
    setIsAvatarUploaded(false);
    setAvatar(null);
  };

  const handleUpdateAvatar = async () => {
    const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_OWNER;
    if (!apiUrl) {
      console.error("API URL is not defined.");
      setIsError(true);
      setFeedbackMessage("Configuration error. Please try again later.");
      setShowBanner(true);
      return;
    }
    try {
      if (!loading && axiosInstance && avatar && user?.owner) {
        const ownerUrl = `${apiUrl}${user.owner.id}/`;
        await axiosInstance.put(ownerUrl, {
          avatar,
          user: user.id,
          features: user.owner.features,
          phone: user.owner.phone,
        });
        setIsError(false);
        setFeedbackMessage("Avatar updated successfully.");
        setShowBanner(true);
      }
    } catch (error) {
      console.error("Error updating avatar:", error);
      setIsError(true);
      setFeedbackMessage("Error updating avatar. Please try again.");
      setShowBanner(true);
    }
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        <h2 className="text-2xl font-semibold mb-4">Settings</h2>
        {showBanner && (
          <WarningBanner
            title={isError ? "Error" : "Success"}
            text={feedbackMessage}
            isSuccess={!isError}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}

        {/* Owner's Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Owner's Information</h3>
          <div className="grid sm:grid-cols-1 md:grid-cols-2">
            <p>
              <strong>Username:</strong> {user?.username}
            </p>
            <p>
              <strong>Email:</strong> {user?.email}
            </p>
            <p>
              <strong>Branches Owned:</strong> {user?.branches.length}
            </p>
          </div>
        </div>

        {/* Update Avatar Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Update Avatar</h3>
          <div
            className={`block w-full border-2 ${
              isAvatarUploaded ? "border-solid" : "border-dotted"
            } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
          >
            <input
              id="avatarUpload"
              type="file"
              className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
              onChange={handleAvatarUpload}
              accept="image/*"
            />
            {avatarPreviewUrl ? (
              <>
                <img
                  src={avatarPreviewUrl}
                  alt="Avatar Preview"
                  className="max-h-full max-w-full p-2"
                />
                <button
                  onClick={handleRemoveAvatar}
                  className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                  style={{ width: "30px", height: "30px" }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </>
            ) : (
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faImage}
                  size="2x"
                  className="text-gray-400 mb-2"
                />
                <p className="text-gray-600 mb-2">
                  Click or drag and drop an image to upload
                </p>
              </div>
            )}
          </div>
          <button
            onClick={handleUpdateAvatar}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            disabled={!avatar}
          >
            Update Avatar
          </button>
        </div>

        {/* Password Reset Email Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Password Reset</h3>
          <p>
            If you need to reset your password, you can send a password reset
            email to your email address.
          </p>
          <button
            onClick={handleSendPasswordResetEmail}
            className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Send Password Reset Email
          </button>
        </div>
      </div>
    </PageLoader>
  );
};

export default Settings;
