import PageLoader from "@/components/back/Spinner";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faCalendarAlt,
  faChevronLeft,
  faChevronRight,
  faEdit,
  faFileExport,
  faFilter,
  faPlus,
  faSearch,
  faSort,
  faSortDown,
  faSortUp,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface CustomerInformation {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  principal_address_id: number;
  lastVisited: string;
}

interface SortState {
  field: string;
  direction: "asc" | "desc";
}

interface CustomerProps {
  onMenuItemClick: (componentName: string) => void;
}

export default function CustomerTable({ onMenuItemClick }: CustomerProps) {
  const { t } = useTranslation();
  const itemsPerPage = 10;
  const [customerInformation, setMCustomerInformation] = useState<
    CustomerInformation[]
  >([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState<SortState>({
    field: "itemName",
    direction: "asc",
  });
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const { user } = useUser();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();

  const fetchCustomerItems = async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_CUSTOMER_BRANCH;
        const response = await axiosInstance.get(
          `${apiUrl}?branch=${user.selectedBranch.id}&_page=${currentPage}&_limit=${itemsPerPage}&q=${searchQuery}`,
        );
        let fetchedItems = response.data.results;
        console.log(response.data.results);
        // Apply sorting with type safety
        fetchedItems.sort((a: CustomerInformation, b: CustomerInformation) => {
          const key = sortState.field as keyof CustomerInformation;
          const aValue = a[key];
          const bValue = b[key];

          if (typeof aValue === "number" && typeof bValue === "number") {
            // Numeric sort
            return sortState.direction === "asc"
              ? aValue - bValue
              : bValue - aValue;
          } else if (typeof aValue === "string" && typeof bValue === "string") {
            // String sort
            const strA = aValue.toUpperCase();
            const strB = bValue.toUpperCase();
            if (strA < strB) {
              return sortState.direction === "asc" ? -1 : 1;
            }
            if (strA > strB) {
              return sortState.direction === "asc" ? 1 : -1;
            }
          }
          return 0;
        });
        setMCustomerInformation(fetchedItems);
        setTotalItems(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchCustomerItems();
    }, 500);

    return () => clearTimeout(timerId);
  }, [currentPage, searchQuery, sortState, axiosInstance, loading]);

  const editItem = (id: number) => {
    console.log("Editing item", id);
  };

  const deleteItem = async (id: number) => {
    try {
      if (!loading && axiosInstance) {
        const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_CUSTOMER_BRANCH ?? "";
        await axiosInstance.delete(`${apiUrl}${id}`);
        fetchCustomerItems();
        setBannerMessage(t("back.management.customer.successDelete"));
        setIsSuccess(true);
        setShowBanner(true);
      }
    } catch (error) {
      console.error("Error deleting customer:", error);
      setBannerMessage(t("back.management.customer.failDelete"));
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleSortChange = (field: string) => {
    const isAsc = sortState.field === field && sortState.direction === "asc";
    setSortState({ field, direction: isAsc ? "desc" : "asc" });
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const downloadCSV = () => {
    const csvRows = [
      ["ID", "Item Name", "Description", "Price"],
      ...customerInformation.map((item) => [
        item.id,
        item.email,
        item.first_name,
        item.last_name,
        item.phone,
        // item.location.city,
        // item.location.state,
        item.lastVisited,
      ]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "customers.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <PageLoader isFetching={isFetching}>
      <BranchRequiredWrapper>
        <div className="container mx-auto px-4 py-2">
          {showBanner && (
            <WarningBanner
              title={isSuccess ? "Success" : "Error"}
              text={bannerMessage}
              isSuccess={isSuccess}
              className={`${
                animateOut ? "animate-slideOutRight" : "animate-slideDown"
              }`}
            />
          )}
          {/* Title and Action Buttons */}
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-2xl font-semibold">
              {t("back.management.customer.title")}
            </h1>
            <div>
              <button
                onClick={downloadCSV}
                className="mr-2 px-4 py-2 bg-gray-200 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
              >
                <FontAwesomeIcon icon={faFileExport} className="mr-2" />
                {t("back.management.menu.product.export")}
              </button>
              <button
                onClick={() => onMenuItemClick("addCustomer")}
                className="px-4 py-2 bg-black text-white rounded-md font-bold shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                <FontAwesomeIcon icon={faPlus} className="mr-2" />
                {t("back.management.customer.addcustomer")}
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center mb-4">
            <div className="relative flex-1 max-w-md mr-4">
              <FontAwesomeIcon
                icon={faSearch}
                className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
              />
              <input
                type="text"
                placeholder="Search product..."
                value={searchQuery}
                onChange={handleSearchChange}
                className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <button className="mr-2 px-4 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50">
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                {t("back.management.menu.product.selectdate")}
              </button>
              <button className="px-7 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50">
                <FontAwesomeIcon icon={faFilter} className="mr-2" />
                {t("back.management.menu.product.filters")}
              </button>
            </div>
          </div>

          {/* Table */}
          <div className="overflow-x-auto">
            <table className="min-w-full text-sm divide-y divide-gray-500">
              <thead>
                <tr>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("email")}
                  >
                    <span className="flex items-center gap-2">
                      {t("back.management.customer.table.email")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "email"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                      />
                    </span>
                  </th>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("name")}
                  >
                    <span className="flex items-center gap-2">
                      {t("back.management.customer.table.name")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "name"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                      />
                    </span>
                  </th>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("phone")}
                  >
                    <span className="flex items-center gap-2">
                      {t("back.management.customer.table.phone")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "phone"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                      />
                    </span>
                  </th>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("location")}
                  >
                    <span className="flex items-center gap-2">
                      {t("back.management.customer.table.location")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "location"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                      />
                    </span>
                  </th>
                  <th
                    className="px-6 py-3 font-bold text-left cursor-pointer"
                    onClick={() => handleSortChange("lastVisited")}
                  >
                    <span className="flex items-center gap-2">
                      {t("back.management.customer.table.lastvisited")}
                      <FontAwesomeIcon
                        icon={
                          sortState.field === "lastVisited"
                            ? sortState.direction === "asc"
                              ? faSortUp
                              : faSortDown
                            : faSort
                        }
                      />
                    </span>
                  </th>
                  <th className="px-6 py-3 font-bold text-left">
                    {t("back.management.menu.product.table.action")}
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-100">
                {customerInformation.map((item) => (
                  <tr key={item.id}>
                    <td className="px-6 py-4 text-gray-900 font-bold">
                      {item.email}
                    </td>
                    <td className="px-6 py-4 text-gray-900 flex items-center font-bold">
                      {item.first_name}
                      <span className="mx-1"></span>
                      {item.last_name}
                    </td>
                    <td className="px-6 py-4 text-gray-900 font-bold">
                      {item.phone}
                    </td>
                    {/* <td className="px-6 py-4 text-gray-900 font-bold">
                  {item.location.city}, {item.location.state}
                </td> */}
                    <td className="px-6 py-4 text-gray-900 font-bold">
                      {item.lastVisited}
                    </td>
                    <td className="px-6 py-4">
                      <button
                        onClick={() => editItem(item.id)}
                        className="mr-2"
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="text-yellow-600 hover:text-yellow-800"
                        />
                      </button>
                      <button onClick={() => deleteItem(item.id)}>
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          className="text-red-600 hover:text-red-800"
                        />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination and results info */}
          <div className="flex justify-between items-center pt-4">
            {/* Results info */}
            <div>
              <p className="text-sm text-gray-700">
                {t("back.management.table.showing")}{" "}
                <span className="font-medium">
                  {(currentPage - 1) * itemsPerPage + 1}
                </span>{" "}
                {t("back.management.table.to")}{" "}
                <span className="font-medium">
                  {Math.min(currentPage * itemsPerPage, totalItems)}
                </span>{" "}
                {t("back.management.table.of")}{" "}
                <span className="font-medium">{totalItems}</span>{" "}
                {t("back.management.table.results")}
              </p>
            </div>

            {/* Pagination component */}
            <div className="flex items-center">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage <= 1}
                className="p-2 disabled:opacity-50"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              {/* Page numbers */}
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index}
                  onClick={() => handlePageChange(index + 1)}
                  className={`p-2 ${
                    currentPage === index + 1
                      ? "text-blue-600"
                      : "text-gray-700"
                  }`}
                >
                  {index + 1}
                </button>
              ))}
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages}
                className="p-2 disabled:opacity-50"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
      </BranchRequiredWrapper>
    </PageLoader>
  );
}
