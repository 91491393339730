import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import PageLoader from "@/components/back/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircle,
  faImage,
  faPlus,
  faTrashAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import OptionListSelectionModal from "@/components/ui/modals/OptionListSelectionModal";
import ContextualSaveBar from "@/components/ui/bars/ContextualSaveBar";
import { Card, CardHeader, CardContent } from "@/components/back/Card";
import toast from "react-hot-toast";
import { OptionList, Product } from "@/utils/types";

interface EditProductProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  productId: number | null;
}

const EditProduct = ({ onMenuItemClick, productId }: EditProductProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [product, setProduct] = useState<Product>({
    id: 0,
    name: "",
    image: "",
    color: "",
    description: "",
    unit_price: "",
    promotion: "",
    stock: false,
    menu_display_name: "",
    category: null,
    product_option_lists: [],
    status: "",
  });
  const [categories, setCategories] = useState<Product[]>([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageBase64, setImageBase64] = useState<string>("");
  const [isOptionListModalOpen, setIsOptionListModalOpen] = useState(false);
  const [originalProduct, setOriginalProduct] = useState<Product | null>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [originalImagePreviewUrl, setOriginalImagePreviewUrl] = useState("");

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        if (!loading && axiosInstance) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
          const response = await axiosInstance.get(`${apiUrl}${productId}/`);
          setProduct(response.data);
          setOriginalProduct(response.data);
          setIsImageUploaded(!!response.data.image);
          setImagePreviewUrl(response.data.image);
          setOriginalImagePreviewUrl(response.data.image);
        }
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    const fetchCategories = async () => {
      if (loading || !axiosInstance || !user?.selectedBranch) return;
      try {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
        const url = `${apiUrl}?branch=${user.selectedBranch.id}&page=1&page_size=100`;

        const response = await axiosInstance.get(url);
        setCategories(response.data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    if (productId) {
      fetchProduct();
      fetchCategories();
    }
  }, [axiosInstance, loading, productId, user?.selectedBranch]);

  useEffect(() => {
    if (originalProduct) {
      const isChanged =
        JSON.stringify(product) !== JSON.stringify(originalProduct) ||
        imagePreviewUrl !== originalImagePreviewUrl;
      setIsDirty(isChanged);
    }
  }, [product, originalProduct, imagePreviewUrl, originalImagePreviewUrl]);

  useEffect(() => {
    if (isImageUploaded !== !!imagePreviewUrl) {
      setIsDirty(true);
    }
  }, [isImageUploaded, imagePreviewUrl]);

  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void,
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  const colorOptions = [
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
    { label: "Yellow", value: "#ffff00" },
    { label: "Purple", value: "#800080" },
    { label: "Orange", value: "#ffa500" },
    { label: "Black", value: "#000000" },
  ];

  const handleColorChange = (value: string) => {
    setProduct({ ...product, color: value });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setIsImageUploaded(true);
      setIsDirty(true); // Add this line

      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        if (typeof base64Result === "string") {
          const base64Data = base64Result.split(",")[1];
          setImageBase64(base64Data);
        }
      });
    }
  };

  const handleUpdateProduct = async () => {
    setIsSaving(true);
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";

        const transformedProductOptionLists = product.product_option_lists.map(
          (optionList) => ({
            ...optionList,
            options: optionList.options.map((option: { id: any }) => option.id),
          }),
        );

        const updatedProduct: any = {
          name: product.name,
          color: product.color,
          description: product.description,
          unit_price: parseFloat(product.unit_price),
          promotion: product.promotion,
          stock: product.stock,
          branch: user.selectedBranch.id,
          category: product.category,
          menu_display_name: product.menu_display_name,
          product_option_lists: transformedProductOptionLists,
          status: product.stock ? "In Stock" : "Out of Stock",
        };

        // Determine if the image has changed
        const imageChanged = imagePreviewUrl !== originalImagePreviewUrl;

        if (imageChanged) {
          if (isImageUploaded && imageBase64) {
            // New image uploaded
            updatedProduct.image = imageBase64;
          } else {
            // Image removed
            updatedProduct.image = null;
          }
        }

        await axiosInstance.put(`${apiUrl}${productId}/`, updatedProduct, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        toast.success("Product updated successfully");
        setOriginalProduct(updatedProduct);
        setOriginalImagePreviewUrl(imagePreviewUrl); // Update original image URL
        setIsDirty(false);
        onMenuItemClick("product");
      }
    } catch (error) {
      console.error("Error updating product:", error);
      toast.error("Failed to update product");
    } finally {
      setIsSaving(false);
    }
  };

  const handleRemoveOptionList = (id: number) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      product_option_lists: prevProduct.product_option_lists.filter(
        (optionList) => optionList.id !== id,
      ),
    }));
  };

  const handleRemoveImage = () => {
    setImagePreviewUrl("");
    setIsImageUploaded(false);
    setImageBase64("");
    setIsDirty(true);
  };

  const handleDiscard = () => {
    if (originalProduct) {
      setProduct(originalProduct);
      setIsDirty(false);
    }
  };

  const handleOptionListSave = (selectedOptionLists: OptionList[]) => {
    const updatedOptionLists = selectedOptionLists.map((optionList) => {
      const existingOptionList = product.product_option_lists.find(
        (existingList) => existingList.id === optionList.id,
      );

      return existingOptionList
        ? { ...existingOptionList, ...optionList }
        : optionList;
    });

    setProduct((prevProduct) => ({
      ...prevProduct,
      product_option_lists: updatedOptionLists,
    }));

    setIsOptionListModalOpen(false);
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        <h1 className="text-xl font-bold">
          {t("back.management.menu.product.editproduct")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="productName"
              >
                {t("back.management.menu.product.productname")}
              </label>
              <input
                type="text"
                id="name"
                placeholder="Enter product name"
                value={product.name}
                onChange={(e) =>
                  setProduct({ ...product, name: e.target.value })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="posMenuColor"
              >
                {t("back.management.menu.product.poscolor")}
              </label>
              <select
                id="posMenuColor"
                value={product.color}
                onChange={(e) => handleColorChange(e.target.value)}
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.product.selectcolor")}
                </option>
                {colorOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {product.color && (
                <FontAwesomeIcon
                  icon={faCircle}
                  color={product.color}
                  className="mt-2"
                />
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="menu_display_name"
              >
                {t("back.management.menu.product.menuDisplayName")}
              </label>
              <input
                type="text"
                placeholder={t(
                  "back.management.menu.product.enterMenuDisplayName",
                )}
                value={product.menu_display_name}
                onChange={(e) =>
                  setProduct({
                    ...product,
                    menu_display_name: e.target.value,
                  })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.product.description")}
            </label>
            <textarea
              id="description"
              placeholder="Enter product description"
              value={product.description}
              onChange={(e) =>
                setProduct({ ...product, description: e.target.value })
              }
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            ></textarea>
          </div>
        </div>

        {/* Media Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.media")}
          </h2>
          <p className="block text-gray-700 text-sm font-bold mt-4 mb-2">
            {t("back.management.menu.product.uploadimage")}
          </p>
          <div
            className={`block w-full border-2 ${
              isImageUploaded ? "border-solid" : "border-dotted"
            } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
          >
            <input
              id="fileUpload"
              type="file"
              className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
              onChange={handleFileChange}
            />
            {imagePreviewUrl ? (
              <>
                <img
                  src={imagePreviewUrl}
                  alt="Preview"
                  className="max-h-full max-w-full p-2"
                />
                {/* <button
                  onClick={handleRemoveImage}
                  className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                  style={{ width: "30px", height: "30px" }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button> */}
              </>
            ) : (
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faImage}
                  size="2x"
                  className="text-gray-400 mb-2"
                />
                <p className="text-gray-600 mb-2">
                  {t("back.management.menu.product.uploadimagehint")}
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Pricing Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.pricing")}
          </h2>
          <input
            type="text"
            placeholder="Base Price"
            value={product.unit_price}
            onChange={(e) =>
              setProduct({ ...product, unit_price: e.target.value })
            }
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          />
        </div>

        {/* Inventory Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.inventory")}
          </h2>
          <input
            type="checkbox"
            id="stock"
            checked={product.stock}
            onChange={(e) =>
              setProduct({ ...product, stock: e.target.checked })
            }
            className="mr-2"
          />
          <label htmlFor="stock" className="text-sm">
            Is Stock Available?
          </label>
        </div>

        {/* Category Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.category")}
          </h2>
          <select
            value={product.category || ""}
            onChange={(e) =>
              setProduct({
                ...product,
                category: e.target.value ? parseInt(e.target.value) : null,
              })
            }
            className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          >
            <option value="">
              {t("back.management.menu.product.selectcategory")}
            </option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        {/* Product Option List Section */}
        <Card className="mb-4">
          <CardHeader className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">{t("Linked OptionLists")}</h2>
            <button
              onClick={() => setIsOptionListModalOpen(true)}
              className="px-3 py-1 bg-gray-500 text-white rounded-md flex items-center"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {t("Edit")}
            </button>
          </CardHeader>

          <CardContent>
            <div className="flex flex-wrap gap-2">
              {product.product_option_lists.map((optionList) => (
                <span
                  key={optionList.id}
                  className="px-2 py-1 bg-red-100 text-red-500 rounded-full text-sm flex items-center"
                >
                  {optionList.name}
                  <button
                    onClick={() => handleRemoveOptionList(optionList.id)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </span>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("product")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.product.cancelbutton")}
          </button>
          <button
            onClick={handleUpdateProduct}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
            disabled={!isDirty || isSaving}
          >
            {t("back.management.menu.product.updatebutton")}
          </button>
        </div>

        <OptionListSelectionModal
          isOpen={isOptionListModalOpen}
          onClose={() => setIsOptionListModalOpen(false)}
          onSave={handleOptionListSave}
          initialSelectedOptionLists={product.product_option_lists.map(
            (optionList) => optionList.id,
          )}
        />

        <ContextualSaveBar
          isDirty={isDirty}
          onSave={handleUpdateProduct}
          onDiscard={handleDiscard}
          isSaving={isSaving}
        />
      </div>
    </PageLoader>
  );
};

export default EditProduct;
