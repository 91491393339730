import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useUser } from "@/contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardContent } from "@/components/back/Card";

interface Option {
  id: number;
  name: string;
  surcharge: number;
}

interface OptionSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (selectedOptions: number[]) => void;
  initialSelectedOptions: number[];
}

const OptionSelectionModal: React.FC<OptionSelectionModalProps> = ({
  isOpen,
  onClose,
  onSave,
  initialSelectedOptions,
}) => {
  const { t } = useTranslation();
  const [axiosInstance, loading] = useAxiosInstance();
  const { user } = useUser();
  const [options, setOptions] = useState<Option[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedOptions, setSelectedOptions] = useState<number[]>(
    initialSelectedOptions,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const itemsPerPage = 20;

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore],
  );

  useEffect(() => {
    if (isOpen) {
      setOptions([]);
      setSearchQuery("");
      setCurrentPage(1);
      setHasMore(true);
      fetchOptions("");
    }
  }, [isOpen]);

  useEffect(() => {
    fetchOptions(searchQuery);
  }, [currentPage, searchQuery]);

  const fetchOptions = async (query: string = "") => {
    if (loading || !axiosInstance || !user?.selectedBranch || isFetching)
      return;
    setIsFetching(true);
    try {
      const apiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_OPTION_INFORMATION ?? "";
      let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;

      if (query) {
        url += `&name=${encodeURIComponent(query)}`;
      }

      const response = await axiosInstance.get(url);
      setOptions((prev) =>
        currentPage === 1
          ? response.data.results
          : [...prev, ...response.data.results],
      );
      setHasMore(response.data.next !== null);
    } catch (error) {
      console.error("Error fetching options:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setOptions([]);
    fetchOptions(searchQuery);
  };

  const handleOptionToggle = (optionId: number) => {
    setSelectedOptions((prev) =>
      prev.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...prev, optionId],
    );
  };

  const handleSave = () => {
    onSave(selectedOptions);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg h-[80vh] flex flex-col">
        <CardHeader className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">{t("Select Options")}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </CardHeader>
        <div className="px-6 py-4">
          <div className="mb-4 flex">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t("Search Option...")}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md"
            />
            <button
              onClick={handleSearch}
              className="px-3 py-2 bg-gray-500 text-white rounded-r-md"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <CardContent className="flex-grow overflow-y-auto">
          {options.map((option, index) => (
            <div
              key={option.id}
              ref={index === options.length - 1 ? lastItemRef : null}
              className="flex items-center justify-between p-2 border-b last:border-b-0"
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id={`option-${option.id}`}
                  checked={selectedOptions.includes(option.id)}
                  onChange={() => handleOptionToggle(option.id)}
                  className="mr-2"
                />
                <label htmlFor={`option-${option.id}`}>{option.name}</label>
              </div>
              <span className="text-right">
                {option.surcharge > 0 && `+$${option.surcharge}`}
              </span>
            </div>
          ))}
          {isFetching && <div className="text-center py-2">Loading...</div>}
        </CardContent>
        <div className="flex justify-end p-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("Confirm")}
          </button>
        </div>
      </Card>
    </div>
  );
};

export default OptionSelectionModal;
