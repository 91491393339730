import WarningBanner from "@/components/ui/banners/WarningBanner";
import BranchOperatingHoursInput from "@/components/ui/selectors/BranchOperatingHoursInput";
import { useUser } from "@/contexts/UserContext";
import { Branch, OperatingHour } from "@/utils/types";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faImage, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import FeatureManagement from "./FeatureManagment";

const BusinessInformation = () => {
  const { user } = useUser();
  const [axiosInstance, loading] = useAxiosInstance();
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState<Branch>({} as Branch);
  const [operatingHours, setOperatingHours] = useState<OperatingHour[]>([]);
  const [logo, setLogo] = useState<string | null>(null);
  const [isLogoUploaded, setIsLogoUploaded] = useState(false);
  const [promotionText, setPromotionText] = useState<string>("");
  const [logoPreviewUrl, setLogoPreviewUrl] = useState("");
  const [featureState, setFeatureState] = useState({
    delivery_feature: false,
    upsell_feature: false,
    loyalty_program_enabled: false,
  });

  // Fetch branches by business name
  const fetchBranchesByBusinessName = useCallback(
    async (businessName: string) => {
      if (loading || !axiosInstance) return;

      try {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/?business_name=${businessName}`;
        const response = await axiosInstance.get(apiUrl);
        const curBranch = response.data.results.find(
          (branch: Branch) => branch.id === user?.selectedBranch?.id,
        );
        setSelectedBranch(curBranch);
        const storedUserData = localStorage.getItem("user_data");
        if (storedUserData) {
          const userData = JSON.parse(storedUserData);
          userData.selectedBranch = curBranch;
          localStorage.setItem("user_data", JSON.stringify(userData));
        }
        setFeatureState({
          delivery_feature: curBranch?.delivery_feature || false,
          upsell_feature: curBranch?.upsell_feature || false,
          loyalty_program_enabled: curBranch?.loyalty_program_enabled || false,
        });
        if (curBranch) {
          setOperatingHours(curBranch.operating_hours);
        }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    },
    [loading, axiosInstance, user],
  );

  // Fetch branches on component mount or when dependencies change
  useEffect(() => {
    const storedUserData = localStorage.getItem("user_data");
    const userData = storedUserData ? JSON.parse(storedUserData) : null;
    const businessName = userData?.owner?.business_name;

    if (businessName) {
      fetchBranchesByBusinessName(businessName);
    }

    // Populate promotion text and logo from localStorage
    if (userData?.selectedBranch) {
      setPromotionText(userData.selectedBranch.promotion_text || "");
      if (userData.selectedBranch.logo) {
        setLogoPreviewUrl(userData.selectedBranch.logo);
        setIsLogoUploaded(true);
      }
    }
  }, [fetchBranchesByBusinessName]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);
      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleChange = async (name: string, value: boolean) => {
    if (loading || !axiosInstance) return;

    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/${selectedBranch.id}/?business_name=${user?.owner?.business_name}`;
      await axiosInstance.put(apiUrl, {
        ...featureState,
        [name]: value,
        user: user?.id,
        name: selectedBranch?.name,
        phone: selectedBranch?.phone,
        email: selectedBranch?.email,
        delivery_cost: selectedBranch?.delivery_cost,
        base_distance: selectedBranch?.base_distance,
        delivery_cost_per_additional_mile:
          selectedBranch?.delivery_cost_per_additional_mile,
        max_distance: selectedBranch?.max_distance,
      });
      setFeatureState((p) => ({ ...p, [name]: value }));
      toast.success("Feature updated successfully.");

      const storedUserData = localStorage.getItem("user_data");
      const userData = storedUserData ? JSON.parse(storedUserData) : null;
      const businessName = userData?.owner?.business_name;
      if (businessName) {
        fetchBranchesByBusinessName(businessName);
      }
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } catch (error) {
      console.error("Error updating delivery feature:", error);
    }
  };

  const handlePromotionTextChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPromotionText(event.target.value);
  };

  const updatePromotionText = async () => {
    if (loading || !axiosInstance) return;

    try {
      const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/${selectedBranch.id}/?business_name=${user?.owner?.business_name}`;
      await axiosInstance.put(apiUrl, {
        user: user?.id,
        name: selectedBranch?.name,
        promotion_text: promotionText,
        phone: selectedBranch?.phone,
        email: selectedBranch?.email,
      });

      // Update localStorage after successful API call
      const storedUserData = localStorage.getItem("user_data");
      if (storedUserData) {
        const userData = JSON.parse(storedUserData);
        if (userData.selectedBranch) {
          userData.selectedBranch.promotion_text = promotionText;
          localStorage.setItem("user_data", JSON.stringify(userData));
        }
      }

      setIsError(false);
      setFeedbackMessage("Promotion text updated successfully.");
      setShowBanner(true);
    } catch (error) {
      console.error("Error updating Promotion Text:", error);
      setIsError(true);
      setFeedbackMessage("Error updating Promotion Text. Please try again.");
      setShowBanner(true);
    }
  };

  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void,
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setLogoPreviewUrl(URL.createObjectURL(file));
      setIsLogoUploaded(true);

      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        if (typeof base64Result === "string") {
          const base64Data = base64Result.split(",")[1];
          setLogo(base64Data);
        }
      });
    }
  };

  const handleRemoveLogo = () => {
    setLogoPreviewUrl("");
    setIsLogoUploaded(false);
    setLogo(null);
  };

  const handleUpdateLogo = async () => {
    if (loading || !axiosInstance) return;

    const apiUrl = `${process.env.REACT_APP_API_BASE_URL}api/v1/branch/${selectedBranch.id}/?business_name=${user?.owner?.business_name}`;
    if (!apiUrl) {
      console.error("API URL is not defined.");
      setIsError(true);
      setFeedbackMessage("Configuration error. Please try again later.");
      setShowBanner(true);
      return;
    }
    try {
      if (logo && user?.owner) {
        const response = await axiosInstance.put(apiUrl, {
          logo: logo,
          user: user?.id,
          name: selectedBranch?.name,
          phone: selectedBranch?.phone,
          email: selectedBranch?.email,
        });

        const storedUserData = localStorage.getItem("user_data");
        if (storedUserData) {
          const userData = JSON.parse(storedUserData);
          if (userData.selectedBranch) {
            userData.selectedBranch.logo = response.data.logo;
            localStorage.setItem("user_data", JSON.stringify(userData));
          }
        }

        setIsError(false);
        setFeedbackMessage("Logo updated successfully.");
        setShowBanner(true);
      }
    } catch (error) {
      console.error("Error updating Logo:", error);
      setIsError(true);
      setFeedbackMessage("Error updating Logo. Please try again.");
      setShowBanner(true);
    }
  };

  return (
    <div>
      {showBanner && (
        <WarningBanner
          title={isError ? "Error" : "Success"}
          text={feedbackMessage}
          isSuccess={!isError}
          className={`${
            animateOut ? "animate-slideOutRight" : "animate-slideDown"
          }`}
        />
      )}
      {/* Branch's Information Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Branch's Information</h3>
        <div className="grid sm:grid-cols-1 md:grid-cols-2">
          <p>
            <strong>Name:</strong> {user?.first_name} {user?.last_name}
          </p>
          <p>
            <strong>Email:</strong> {user?.email}
          </p>
        </div>
      </div>

      {/* Feature Section */}
      <FeatureManagement
        featureState={featureState}
        handleChange={handleChange}
      />

      {/* Branch Delivery Cost Section */}
      {featureState.delivery_feature && (
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h3 className="text-lg font-semibold mb-2">Branch Delivery Cost</h3>
          <p className="pb-4">
            You can set a delivery cost for each branch. This cost will be added
            to the total amount of the order.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
            <div className="grid grid-cols-2 lg:grid-cols-4 items-center">
              <label className="block text-gray-700">Delivery Cost:</label>
              <input
                type="number"
                value={selectedBranch.delivery_cost}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline lg:col-span-2"
                placeholder="0.00"
                onChange={(e) =>
                  setSelectedBranch((p) => {
                    return {
                      ...p,
                      delivery_cost: parseFloat(e.target.value),
                    };
                  })
                }
              />
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 items-center">
              <label className="block text-gray-700">Base Distance:</label>
              <input
                type="number"
                value={selectedBranch.base_distance}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline lg:col-span-2"
                placeholder="0.00"
                onChange={(e) =>
                  setSelectedBranch((p) => {
                    return {
                      ...p,
                      base_distance: parseFloat(e.target.value),
                    };
                  })
                }
              />
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 items-center">
              <label className="block text-gray-700">
                Delivery Cost Per Additional Mile:
              </label>
              <input
                type="number"
                value={selectedBranch.delivery_cost_per_additional_mile}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline lg:col-span-2"
                placeholder="0.00"
                onChange={(e) =>
                  setSelectedBranch((p) => {
                    return {
                      ...p,
                      delivery_cost_per_additional_mile: parseFloat(
                        e.target.value,
                      ),
                    };
                  })
                }
              />
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-4 items-center">
              <label className="block text-gray-700">Max Distance</label>
              <input
                type="number"
                value={selectedBranch.max_distance}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline lg:col-span-2"
                placeholder="0.00"
                onChange={(e) =>
                  setSelectedBranch((p) => {
                    return {
                      ...p,
                      max_distance: parseFloat(e.target.value),
                    };
                  })
                }
              />
            </div>
          </div>
          <div>
            <button
              onClick={() =>
                handleChange("delivery_feature", featureState.delivery_feature)
              }
              className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Save
            </button>
          </div>
        </div>
      )}

      {/* Update Promotion Text Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <div className="col-span-2 mt-4">
          <label
            htmlFor="promotionText"
            className="block text-sm font-medium text-gray-700"
          >
            Promotion Text
          </label>
          <input
            id="promotionText"
            type="text"
            value={promotionText}
            onChange={handlePromotionTextChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
        </div>
        <div className="col-span-2 mt-4">
          <button
            onClick={updatePromotionText}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Save Promotion Text
          </button>
        </div>
      </div>

      {/* Update Logo Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h3 className="text-lg font-semibold mb-2">Update Branch Logo</h3>
        <div
          className={`block w-full border-2 ${
            isLogoUploaded ? "border-solid" : "border-dotted"
          } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
        >
          <input
            id="avatarUpload"
            type="file"
            className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
            onChange={handleLogoUpload}
            accept="image/*"
          />
          {logoPreviewUrl ? (
            <>
              <img
                src={logoPreviewUrl}
                alt="Avatar Preview"
                className="max-h-full max-w-full p-2"
              />
              <button
                onClick={handleRemoveLogo}
                className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                style={{ width: "30px", height: "30px" }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </>
          ) : (
            <div className="text-center">
              <FontAwesomeIcon
                icon={faImage}
                size="2x"
                className="text-gray-400 mb-2"
              />
              <p className="text-gray-600 mb-2">
                Click or drag and drop an image to upload
              </p>
            </div>
          )}
        </div>
        <button
          onClick={handleUpdateLogo}
          className="mt-4 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-500 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          disabled={!logo}
        >
          Update Branch Logo
        </button>
      </div>

      {/* Branch Operating Hours Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <BranchOperatingHoursInput
          operatingHours={operatingHours}
          setOperatingHours={setOperatingHours}
          branchId={selectedBranch?.id || 0}
        />
      </div>
    </div>
  );
};

export default BusinessInformation;
