import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";

import PrivacyPolicy from "@/views/front/privacyPolicy";
import TermsOfService from "@/views/front/terms";
import Contact from "../views/front/Contact";
import Landing from "../views/front/Landing";
import Pricing from "../views/front/Pricing";

import FeatureHandhelds from "@/views/front/FeatureHandhelds";
import FeatureKiosks from "@/views/front/FeatureKiosks";
import FeatureLoyalty from "@/views/front/FeatureLoyalty";
import FeatureOnline from "@/views/front/FeatureOnline";
import FeaturePos from "@/views/front/FeaturePos";
import FeatureIntegrations from "../views/front/FeatureIntegrations";
import FeaturePS from "../views/front/FeaturePS";
import FeaturePTM from "../views/front/FeaturePTM";
import FeatureWR from "../views/front/FeatureWR";

import BusinessBarNightclub from "../views/front/BusinessBarNightclub";
import BusinessBuffet from "../views/front/BusinessBuffet";
import BusinessCafeBakery from "../views/front/BusinessCafeBakery";
import BusinessFS from "../views/front/BusinessFS";
import BusinessHotpotBBQ from "../views/front/BusinessHotpotBBQ";
import BusinessQS from "../views/front/BusinessQS";

import Aboutus from "../views/front/AboutUs";
import CompanyCareers from "../views/front/CompanyCareers";
import CompanyTestimonials from "../views/front/CompanyTestimonials";

import Login from "@/views/front/account/Login";
import Register from "@/views/front/account/Register";
import ResetPassword from "@/views/front/account/ResetPassword";

import CartPage from "@/components/front/CartPage";
import OrderConfirmation from "@/components/front/OrderConfirmation";
import Management from "@/views/back/Management";
import TestStore from "@/views/front/TestStore";

import CustomerLogin from "@/components/front/CustomerLogin";
import CustomerRegister from "@/components/front/CustomerRegister";
import ProfilePage from "@/components/front/ProfilePage";
import DownloadApp from "@/views/front/DownloadApp";
import useEventTracking from "@/utils/eventsHook";

const AppRoutes = () => {
  useEventTracking();
  return (
    <Routes>
      <Route path="/">
        <Route index element={<Landing />} />
        <Route path="cart" element={<CartPage />} />
        <Route path="order-confirmation" element={<OrderConfirmation />} />
        <Route
          path="contact"
          element={<Contact stickyFooter={true} isStandalone />}
        />
        <Route path="/customer-login" element={<CustomerLogin />} />
        <Route path="/customer-register" element={<CustomerRegister />} />
        <Route path="/customer-profile" element={<ProfilePage />} />
        <Route path="pricing" element={<Pricing />} />

        <Route path="features/pos" element={<FeaturePos />} />
        <Route path="features/handhelds" element={<FeatureHandhelds />} />
        <Route path="features/kiosks" element={<FeatureKiosks />} />
        <Route
          path="features/onlineorderingdelivery"
          element={<FeatureOnline />}
        />
        <Route path="features/loyaltyprogram" element={<FeatureLoyalty />} />
        <Route path="features/waitlistreservations" element={<FeatureWR />} />
        <Route path="features/paymentssecurity" element={<FeaturePS />} />
        <Route path="features/payrollteammanagement" element={<FeaturePTM />} />
        <Route
          path="features/redbirdintegrations"
          element={<FeatureIntegrations />}
        />

        <Route path="business/quickservice" element={<BusinessQS />} />
        <Route path="business/fullservice" element={<BusinessFS />} />
        <Route path="business/cafebakery" element={<BusinessCafeBakery />} />
        <Route path="business/hotpotbbq" element={<BusinessHotpotBBQ />} />
        <Route
          path="business/barnightclub"
          element={<BusinessBarNightclub />}
        />
        <Route path="business/buffet" element={<BusinessBuffet />} />
        <Route path="company/aboutus" element={<Aboutus />} />
        <Route path="company/careers" element={<CompanyCareers />} />

        <Route path="company/testimonials" element={<CompanyTestimonials />} />

        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/login/reset-password" element={<ResetPassword />} />

        <Route path="/downloadapp" element={<DownloadApp />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsOfService />} />
        <Route path="store" element={<TestStore />} />
        <Route path="store2" element={<TestStore />} />

        <Route element={<ProtectedRoute />}>
          <Route path="/user/management" element={<Management />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
