import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCalendarAlt,
  faFilter,
  faSearch,
  faEye,
  faTrashAlt,
  faSort,
  faSortUp,
  faSortDown,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface ExpenseDetail {
  id: number;
  date: string;
  payTo: string;
  total: string;
  payment: string;
  type: string;
}

interface SortState {
  field: string;
  direction: "asc" | "desc";
}

export default function ExpenseDetail() {
  const { t } = useTranslation();
  const itemsPerPage = 10;
  const [expenseDeatail, setExpenseDeatail] = useState<ExpenseDetail[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState<SortState>({
    field: "itemName",
    direction: "asc",
  });

  const viewItem = (id: number) => {
    console.log("Editing item", id);
  };

  const deleteItem = (id: number) => {
    console.log("Deleting item", id);
  };

  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const response = await axios.get(
          `http://localhost:3001/expenses?_page=${currentPage}&_limit=${itemsPerPage}&q=${searchQuery}`,
        );
        let fetchedItems = response.data;
        // Apply sorting with type safety
        fetchedItems.sort((a: ExpenseDetail, b: ExpenseDetail) => {
          const key = sortState.field as keyof ExpenseDetail;
          const aValue = a[key];
          const bValue = b[key];

          if (typeof aValue === "number" && typeof bValue === "number") {
            // Numeric sort
            return sortState.direction === "asc"
              ? aValue - bValue
              : bValue - aValue;
          } else if (typeof aValue === "string" && typeof bValue === "string") {
            // String sort
            const strA = aValue.toUpperCase();
            const strB = bValue.toUpperCase();
            if (strA < strB) {
              return sortState.direction === "asc" ? -1 : 1;
            }
            if (strA > strB) {
              return sortState.direction === "asc" ? 1 : -1;
            }
          }
          return 0;
        });
        setExpenseDeatail(fetchedItems);
        setTotalItems(parseInt(response.headers["x-total-count"], 10));
      } catch (error) {
        console.error("Error fetching menu items:", error);
      }
    };
    const timerId = setTimeout(() => {
      fetchMenuItems();
    }, 500);
    return () => clearTimeout(timerId);
  }, [currentPage, searchQuery, sortState]);

  const handleSortChange = (field: string) => {
    const isAsc = sortState.field === field && sortState.direction === "asc";
    setSortState({ field, direction: isAsc ? "desc" : "asc" });
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <div className="container mx-auto px-4 py-2 mt-10">
      {/* Title and Action Buttons */}
      <div className="flex justify-between items-center mb-4">
        <div className="relative flex-1 max-w-md mr-4">
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
          <input
            type="text"
            placeholder="Search product..."
            value={searchQuery}
            onChange={handleSearchChange}
            className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div>
          <button className="mr-2 px-4 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50">
            <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
            {t("back.management.menu.product.selectdate")}
          </button>
          <button className="px-7 py-2 bg-white text-gray-700 rounded-md shadow hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50">
            <FontAwesomeIcon icon={faFilter} className="mr-2" />
            {t("back.management.menu.product.filters")}
          </button>
        </div>
      </div>

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full text-sm divide-y divide-gray-500">
          <thead>
            <tr>
              <th
                className="px-6 py-3 font-bold text-left cursor-pointer"
                onClick={() => handleSortChange("id")}
              >
                <span className="flex items-center gap-2">
                  {t("back.management.expense.table.id")}
                  <FontAwesomeIcon
                    icon={
                      sortState.field === "id"
                        ? sortState.direction === "asc"
                          ? faSortUp
                          : faSortDown
                        : faSort
                    }
                  />
                </span>
              </th>
              <th
                className="px-6 py-3 font-bold text-left cursor-pointer"
                onClick={() => handleSortChange("date")}
              >
                <span className="flex items-center gap-2">
                  {t("back.management.expense.table.date")}
                  <FontAwesomeIcon
                    icon={
                      sortState.field === "date"
                        ? sortState.direction === "asc"
                          ? faSortUp
                          : faSortDown
                        : faSort
                    }
                  />
                </span>
              </th>
              <th
                className="px-6 py-3 font-bold text-left cursor-pointer"
                onClick={() => handleSortChange("payTo")}
              >
                <span className="flex items-center gap-2">
                  {t("back.management.expense.table.payto")}
                  <FontAwesomeIcon
                    icon={
                      sortState.field === "payTo"
                        ? sortState.direction === "asc"
                          ? faSortUp
                          : faSortDown
                        : faSort
                    }
                  />
                </span>
              </th>
              <th
                className="px-6 py-3 font-bold text-left cursor-pointer"
                onClick={() => handleSortChange("totalAmount")}
              >
                <span className="flex items-center gap-2">
                  {t("back.management.order.table.total")}
                  <FontAwesomeIcon
                    icon={
                      sortState.field === "totalAmount"
                        ? sortState.direction === "asc"
                          ? faSortUp
                          : faSortDown
                        : faSort
                    }
                  />
                </span>
              </th>
              <th
                className="px-6 py-3 font-bold text-left cursor-pointer"
                onClick={() => handleSortChange("payment")}
              >
                <span className="flex items-center gap-2">
                  {t("back.management.order.table.payment")}
                  <FontAwesomeIcon
                    icon={
                      sortState.field === "payment"
                        ? sortState.direction === "asc"
                          ? faSortUp
                          : faSortDown
                        : faSort
                    }
                  />
                </span>
              </th>
              <th
                className="px-6 py-3 font-bold text-left cursor-pointer"
                onClick={() => handleSortChange("type")}
              >
                <span className="flex items-center gap-2">
                  {t("back.management.expense.table.type")}
                  <FontAwesomeIcon
                    icon={
                      sortState.field === "type"
                        ? sortState.direction === "asc"
                          ? faSortUp
                          : faSortDown
                        : faSort
                    }
                  />
                </span>
              </th>
              <th className="px-6 py-3 font-bold text-left">
                {t("back.management.menu.product.table.action")}
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {expenseDeatail.map((item) => (
              <tr key={item.id}>
                <td className="px-6 py-4 text-gray-900 font-bold">{item.id}</td>
                <td className="px-6 py-4 text-gray-900">{item.date}</td>
                <td className="px-6 py-4 text-gray-900">{item.payTo}</td>
                <td className="px-6 py-4 text-gray-900 font-bold">
                  ${item.total}
                </td>
                <td className="px-6 py-4 text-gray-900">{item.payment}</td>
                <td className="px-6 py-4 text-gray-900 font-bold">
                  {item.type}
                </td>
                <td className="px-6 py-4">
                  <button onClick={() => viewItem(item.id)} className="mr-2">
                    <FontAwesomeIcon
                      icon={faEye}
                      className="text-yellow-600 hover:text-yellow-800"
                    />
                  </button>
                  <button onClick={() => deleteItem(item.id)}>
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      className="text-red-600 hover:text-red-800"
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination and results info */}
      <div className="flex justify-between items-center pt-4">
        {/* Results info */}
        <div>
          <p className="text-sm text-gray-700">
            {t("back.management.menu.product.table.showing")}{" "}
            <span className="font-medium">
              {(currentPage - 1) * itemsPerPage + 1}
            </span>{" "}
            {t("back.management.menu.product.table.to")}{" "}
            <span className="font-medium">
              {Math.min(currentPage * itemsPerPage, totalItems)}
            </span>{" "}
            {t("back.management.menu.product.table.of")}{" "}
            <span className="font-medium">{totalItems}</span>{" "}
            {t("back.management.menu.product.table.results")}
          </p>
        </div>

        {/* Pagination component */}
        <div className="flex items-center">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage <= 1}
            className="p-2 disabled:opacity-50"
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          {/* Page numbers */}
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => handlePageChange(index + 1)}
              className={`p-2 ${
                currentPage === index + 1 ? "text-blue-600" : "text-gray-700"
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage >= totalPages}
            className="p-2 disabled:opacity-50"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
      </div>
    </div>
  );
}
