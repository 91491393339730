async function registerServiceWorker(
  scope: string = "/",
): Promise<ServiceWorkerRegistration | null> {
  if (!("serviceWorker" in navigator)) return null;

  try {
    const registration = await navigator.serviceWorker.register(
      "/service-worker.js",
      {
        scope: scope,
        updateViaCache: "none",
      },
    );
    console.log("SW registered: ", registration);
    return registration;
  } catch (error) {
    console.error("Service worker registration failed:", error);
    return null;
  }
}

function checkForUpdates(registration: ServiceWorkerRegistration) {
  // Check for updates every 5 minutes
  setInterval(
    () => {
      registration.update();
    },
    5 * 60 * 1000,
  );

  registration.update();

  registration.addEventListener("updatefound", () => {
    const newWorker = registration.installing;
    if (newWorker) {
      newWorker.addEventListener("statechange", () => {
        if (
          newWorker.state === "installed" &&
          navigator.serviceWorker.controller
        ) {
          notifyUserOfUpdate(registration);
        }
      });
    }
  });
}

function notifyUserOfUpdate(registration: ServiceWorkerRegistration): void {
  console.log("Notifying user of update");
  if (window.confirm("New version available. Update now?")) {
    registration.waiting?.postMessage({ type: "SKIP_WAITING" });
  }
}

export async function subscribeToPushNotifications(id: string) {
  const registration = await registerServiceWorker();
  if (!registration) return;

  try {
    let subscription = await registration.pushManager.getSubscription();
    if (!subscription) {
      subscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: process.env.REACT_VAPID_PUBLIC_KEY,
      });
    }

    const requestBody = {
      endpoint: subscription.endpoint,
      p256dh: subscription.toJSON()?.keys?.p256dh,
      auth: subscription.toJSON()?.keys?.auth,
      userId: id,
    };

    const res = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}auth/pnconfig/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      },
    );

    if (!res.ok) {
      throw new Error("Bad status code from server.");
    }
    console.log("Push notification subscription successful");
  } catch (error) {
    console.error("Push notification subscription failed:", error);
  }
}

let serviceWorkerInitialized = false;

export function initializeServiceWorker(currentPath: string) {
  if (serviceWorkerInitialized) return;
  serviceWorkerInitialized = true;

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", async () => {
      try {
        let registration;

        if (
          currentPath.startsWith("/login") ||
          currentPath.startsWith("/user/management")
        ) {
          // Register full service worker for PWA paths
          registration = await navigator.serviceWorker.register(
            "/service-worker.js",
            {
              scope: "/",
            },
          );

          if (registration) {
            checkForUpdates(registration);
          }
          console.log("Full service worker registered for PWA paths");
        } else {
          // For other paths, use the minimal service worker
          registration = await navigator.serviceWorker.register(
            "/minimal-service-worker.js",
            {
              scope: "/",
            },
          );
          console.log("Minimal service worker registered for non-PWA paths");
        }
      } catch (error) {
        console.error("Service worker initialization failed:", error);
      }
    });
  }
}
