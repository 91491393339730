/*

import { combineReducers, createStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "./localStorage";
import themeReducer from "./modules/themeReducer";
import pricingPlanReducer from "./modules/pricingPlanReducer";
// import authReducer from "./modules/authReducer";
import throttle from "lodash.throttle";
import cart from "@/features/cart/cart";
// import accountReducer from "./modules/accountReducer";
// import tenantReducer from "./modules/tenantReducer";
// import pricingReducer from "./modules/pricingReducer";
// import appReducer from "./modules/appReducer";

const reducers = combineReducers({
//   account: accountReducer,
//   auth: authReducer,
//   tenant: tenantReducer,
//   pricing: pricingReducer,
  theme: themeReducer,
  pricingPlan: pricingPlanReducer,
  foodCart: cart,
//   app: appReducer,
});

const persistedState = loadState();
const store = createStore(reducers, persistedState);
store.subscribe(
  throttle(() => {
    saveState({
    //   account: store.getState().account,
    //   auth: store.getState().auth,
    //   tenant: store.getState().tenant,
    //   pricing: store.getState().pricing,
      theme: store.getState().theme,
      pricingPlan: store.getState().pricingPlan,
      foodCart: store.getState().foodCart,
      // cart: store.getState().cart,
    //   app: store.getState().app,
    });
  }, 1000)
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

*/
import { combineReducers, createStore } from "@reduxjs/toolkit";
import { loadState, saveState } from "./localStorage";
import themeReducer from "./modules/themeReducer";
import pricingPlanReducer from "./modules/pricingPlanReducer";
import throttle from "lodash.throttle";
import foodCartReducer from "@/features/cart/cart";

const reducers = combineReducers({
  theme: themeReducer,
  pricingPlan: pricingPlanReducer,
  foodCart: foodCartReducer,
});

const persistedState = loadState();
const store = createStore(reducers, persistedState);

store.subscribe(
  throttle(() => {
    saveState({
      theme: store.getState().theme,
      pricingPlan: store.getState().pricingPlan,
      foodCart: store.getState().foodCart,
    });
  }, 1000),
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
