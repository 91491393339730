import FilterOptionsButton from "@/components/back/FilterOptionsButton";
import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import ContextualSaveBar from "@/components/ui/bars/ContextualSaveBar";
import ProductSelectionModal from "@/components/ui/modals/ProductSelectionModal";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface UpsellProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number,
  ) => void;
  successMessage: string;
}

interface FilterState {
  status: string;
  category: string;
  priceGt: string;
  priceLt: string;
}

interface ProductItem {
  id: number;
  name: string;
  unit_price: string;
  category: string;
  category_name: string;
  created_at: string;
  imageUrl: string;
  is_upsell: boolean;
}

interface Product {
  id: number;
  name: string;
  menu_display_name: string;
}

export default function Upsell({
  onMenuItemClick,
  successMessage,
}: UpsellProps) {
  const { t } = useTranslation();
  const { user } = useUser();
  const [showProductSelection, setShowProductSelection] = useState(false);
  const [upsellProducts, setUpsellProducts] = useState<ProductItem[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [axiosInstance, isFetching] = useAxiosInstance();
  const [filterState, setFilterState] = useState<FilterState>({
    status: "",
    category: "",
    priceGt: "",
    priceLt: "",
  });
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  const fetchUpsellProducts = useCallback(async () => {
    try {
      if (axiosInstance && user?.selectedBranch) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
        let url = `${apiUrl}?branch=${
          user.selectedBranch.id
        }&is_upsell=true&page=${currentPage}&page_size=10&menu_display_name=${encodeURIComponent(
          searchTerm,
        )}`;
        if (filterState.status) {
          url += `&status=${filterState.status}`;
        }
        if (filterState.category) {
          url += `&category=${encodeURIComponent(filterState.category)}`;
        }
        if (filterState.priceGt) {
          url += `&unit_price__gt=${filterState.priceGt}`;
        }
        if (filterState.priceLt) {
          url += `&unit_price__lt=${filterState.priceLt}`;
        }

        const response = await axiosInstance.get(url);
        setUpsellProducts(response.data.results);
        setTotalPages(Math.ceil(response.data.count / 10));
      }
    } catch (error) {
      console.error("Error fetching upsell products:", error);
    }
  }, [axiosInstance, user, currentPage, searchTerm, filterState]);

  useEffect(() => {
    fetchUpsellProducts();
  }, [fetchUpsellProducts]);

  const handleAddProduct = () => {
    setShowProductSelection(true);
  };

  const handleFilterChange = (field: keyof FilterState, value: string) => {
    setFilterState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
    setIsDirty(true);
  };

  const handleRemoveFilters = () => {
    setFilterState({
      status: "",
      category: "",
      priceGt: "",
      priceLt: "",
    });
    setCurrentPage(1);
    fetchUpsellProducts();
    setIsDirty(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      setCurrentPage(1);
      await fetchUpsellProducts();
    };

    fetchData();
  }, [filterState]);

  const handleSaveProducts = async (selectedProductIds: number[]) => {
    try {
      for (const productId of selectedProductIds) {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
        await axiosInstance.patch(`${apiUrl}${productId}/`, {
          is_upsell: true,
        });
      }
      await fetchUpsellProducts();
      setIsDirty(false);
      setBannerMessage("Products saved successfully.");
      setIsSuccess(true);
      setShowBanner(true);
    } catch (error) {
      console.error("Error saving upsell products:", error);
      setBannerMessage("Failed to save products.");
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  //wrapper function that maps the Product objects to Id's
  const handleProductSave = (selectedProducts: Product[]) => {
    const selectedProductIds = selectedProducts.map((product) => product.id);
    handleSaveProducts(selectedProductIds);
  };

  const handleDeleteProduct = async (productId: number) => {
    try {
      const apiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
      await axiosInstance.patch(`${apiUrl}${productId}/`, { is_upsell: false });
      await fetchUpsellProducts();
      setIsDirty(true);
      setBannerMessage("Product deleted successfully.");
      setIsSuccess(true);
      setShowBanner(true);
    } catch (error) {
      console.error("Error deleting upsell product:", error);
      setBannerMessage("Failed to delete product.");
      setIsSuccess(false);
      setShowBanner(true);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSave = async () => {
    setIsSaving(true);
    try {
      await fetchUpsellProducts();
      setIsDirty(false);
      setBannerMessage("Changes saved successfully.");
      setIsSuccess(true);
      setShowBanner(true);
    } catch (error) {
      console.error("Error saving upsell products:", error);
      setBannerMessage("Failed to save changes.");
      setIsSuccess(false);
      setShowBanner(true);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDiscard = () => {
    setIsDirty(false);
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  return (
    <PageLoader isFetching={isFetching}>
      <ContextualSaveBar
        isDirty={isDirty}
        onSave={handleSave}
        onDiscard={handleDiscard}
        isSaving={isSaving}
      />
      <div className="p-4">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-xl font-bold">{t("Upsell Product")}</h1>
          <button
            onClick={handleAddProduct}
            className="bg-black text-white py-2 px-4 rounded flex items-center"
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            {t("Add Product")}
          </button>
        </div>
        <div className="flex justify-between items-center mb-4">
          <input
            type="text"
            placeholder={t("Search product...")}
            value={searchTerm}
            onChange={handleSearchChange}
            className="border rounded py-2 px-4 w-full md:w-1/2"
          />
          <div className="relative">
            <FilterOptionsButton
              filterOptions={[
                {
                  label: "status",
                  value: filterState.status,
                  onChange: (value) => handleFilterChange("status", value),
                  placeholder: t("Select Status"),
                  type: "select",
                  options: [
                    {
                      value: "In Stock",
                      label: "In Stock",
                    },
                    {
                      value: "Low Stock",
                      label: "Low Stock",
                    },
                    {
                      value: "Out of Stock",
                      label: "Out of Stock",
                    },
                  ],
                },
                {
                  label: "Category",
                  value: filterState.category,
                  onChange: (value) => handleFilterChange("category", value),
                  placeholder: t("Enter Category"),
                  type: "input",
                },
                {
                  label: "Price greater than",
                  value: filterState.priceGt,
                  onChange: (value) => handleFilterChange("priceGt", value),
                  placeholder: "Enter Price",
                  type: "input",
                },
                {
                  label: "Price less than",
                  value: filterState.priceLt,
                  onChange: (value) => handleFilterChange("priceLt", value),
                  placeholder: "Enter Price",
                  type: "input",
                },
              ]}
              onRemoveFilters={handleRemoveFilters}
            />
          </div>
        </div>
        <div className="space-y-4">
          {upsellProducts.map((product) => (
            <div
              key={product.id}
              className="flex items-center bg-white p-4 rounded shadow"
            >
              <div className="w-16 h-16 rounded overflow-hidden">
                <img
                  src={product.imageUrl}
                  alt={product.name}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="ml-4 flex-1">
                <h2 className="text-lg font-bold">{product.name}</h2>
                <p className="text-gray-500">{product.unit_price}</p>
                <p className="text-gray-500">{product.category_name}</p>
                <p className="text-gray-500">
                  {t("ADD")}: {product.created_at.split("T")[0]}
                </p>
              </div>
              <button
                className="text-red-500"
                onClick={() => handleDeleteProduct(product.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
                {t("DELETE")}
              </button>
            </div>
          ))}
        </div>
        <div className="flex justify-center mt-4">
          <nav className="inline-flex">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={`px-3 py-1 border ${
                  index + 1 === currentPage
                    ? "bg-red-500 text-white"
                    : "bg-white text-black"
                }`}
              >
                {index + 1}
              </button>
            ))}
          </nav>
        </div>
        <ProductSelectionModal
          isOpen={showProductSelection}
          onClose={() => setShowProductSelection(false)}
          onSave={handleProductSave}
          initialSelectedProducts={upsellProducts.map((p) => p.id)}
        />
      </div>
    </PageLoader>
  );
}
