import { Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import classNames from "@/utils/shared/ClassesUtils";

interface Props {
  right?: boolean;
  button?: ReactNode;
  options?: ReactNode;
  isOpen?: boolean;
  className?: string;
}

export default function Dropdown({
  button,
  options,
  right,
  isOpen,
  className,
}: Props) {
  return (
    <Menu
      as="div"
      className={classNames(className, "relative inline-block text-left")}
    >
      <div className="cursor-pointer">{button}</div>
      <Transition
        show={isOpen}
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          className={classNames(
            "z-40 absolute mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none",
            right ? "origin-top-left left-0" : "origin-top-right right-0",
          )}
        >
          <div className="py-1">{options}</div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
