import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

interface OperatingHoursInputProps {
  operatingHours: OperatingHour[];
  setOperatingHours: (hours: OperatingHour[]) => void;
}

const daysOfWeek = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const OperatingHoursInput: React.FC<OperatingHoursInputProps> = ({
  operatingHours,
  setOperatingHours,
}) => {
  const { t } = useTranslation();
  const handleAddOperatingHour = () => {
    setOperatingHours([
      ...operatingHours,
      {
        id: Date.now(),
        day: "Monday",
        open_time: "09:00",
        close_time: "17:00",
      },
    ]);
  };

  const handleRemoveOperatingHour = (id: number) => {
    setOperatingHours(operatingHours.filter((hour) => hour.id !== id));
  };

  const handleDayChange = (id: number, day: string) => {
    setOperatingHours(
      operatingHours.map((hour) => (hour.id === id ? { ...hour, day } : hour)),
    );
  };

  const handleTimeChange = (
    id: number,
    type: "open_time" | "close_time",
    time: string,
  ) => {
    setOperatingHours(
      operatingHours.map((hour) =>
        hour.id === id ? { ...hour, [type]: time } : hour,
      ),
    );
  };

  return (
    <div className="mt-2">
      {operatingHours.map((hour) => (
        <div
          key={hour.id}
          className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 items-center mb-2"
        >
          <select
            value={hour.day}
            onChange={(e) => handleDayChange(hour.id, e.target.value)}
            className="p-2 border rounded w-full md:max-w-xs"
          >
            {daysOfWeek.map((day) => (
              <option key={day} value={day}>
                {day}
              </option>
            ))}
          </select>
          <div className="flex space-x-2">
            <input
              type="time"
              value={hour.open_time}
              onChange={(e) =>
                handleTimeChange(hour.id, "open_time", e.target.value)
              }
              className="p-2 border rounded"
            />
            <input
              type="time"
              value={hour.close_time}
              onChange={(e) =>
                handleTimeChange(hour.id, "close_time", e.target.value)
              }
              className="p-2 border rounded"
            />
            <button
              onClick={() => handleRemoveOperatingHour(hour.id)}
              className="p-2 bg-red-500 text-white rounded"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
      ))}
      <button
        onClick={handleAddOperatingHour}
        className="p-2 bg-red-500 text-white rounded mt-2"
      >
        <FontAwesomeIcon icon={faPlus} className="mr-2" />
        {t("back.management.menu.category.addOperatingHour")}
      </button>
    </div>
  );
};

export default OperatingHoursInput;
