import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareXTwitter,
  faSquareInstagram,
  faSquareFacebook,
} from "@fortawesome/free-brands-svg-icons";

export default function Footer() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const featureNameMapping: { [key: string]: string } = {
    POS: "POS系统",
    Handhelds: "手持设备",
    Kiosks: "自助服务终端",
    OnlineOrderingDelivery: "在线点餐与配送",
    LoyaltyProgram: "忠诚度计划",
    WaitlistReservations: "等位与预订",
    PaymentsSecurity: "支付与安全",
    PayrollTeamManagement: "薪资与团队管理",
    RedBirdIntegrations: "RedBird 集成",
    pos: "POS",
    handhelds: "Handhelds",
    kiosks: "Kiosks",
    onlineOrderingDelivery: "Online Ordering & Delivery",
    loyaltyProgram: "Loyalty Program",
    waitlistReservations: "Waitlist & Reservations",
    paymentsSecurity: "Payments & Security",
    payrollTeamManagement: "Payroll & Team Management",
    redBirdIntegrations: "RedBird Integrations",
  };

  const companyNameMapping: { [key: string]: string } = {
    aboutus: "关于我们",
    testimonials: "用户评价",
    contact: "联系我们",
    careers: "招聘信息",
    Aboutus: "About Us",
    Testimonials: "Testimonials",
    Contact: "Contact Us",
    Careers: "Careers",
  };

  const businessNameMapping: { [key: string]: string } = {
    quickservice: "快速服务",
    fullservice: "全方位服务",
    cafebakery: "咖啡厅与面包店",
    hotpotbbq: "火锅与烧烤",
    barnightclub: "酒吧与夜店",
    buffet: "自助餐",
    Quickservice: "Quick Service",
    Fullservice: "Full Service",
    Cafebakery: "Cafe & Bakery",
    Hotpotbbq: "Hot Pot & BBQ",
    Barnightclub: "Bar & Nightclub",
    Buffet: "Buffet",
  };

  const getEnglishPath = (
    nameMapping: { [key: string]: string },
    translatedName: string,
  ): string => {
    const englishName = Object.keys(nameMapping).find(
      (key) => nameMapping[key] === translatedName,
    );
    return (
      englishName?.toLowerCase().replace(/ & | /g, "").replace(/[\s/]/g, "-") ||
      ""
    );
  };

  return (
    <div>
      <footer className="bg-gray-900">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
          <div className="hidden lg:flex justify-center mb-8">
            <button
              onClick={() => navigate("/downloadapp")}
              className="flex items-center justify-center p-4 bg-white rounded-full shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <img
                src="/logo512.png"
                alt="Download App"
                className="h-10 w-10 mr-3"
              />
              <span className="text-lg font-bold text-gray-900">
                {t("front.footer.downloadApp", "Download Our App")}
              </span>
            </button>
          </div>
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div className="px-5 py-2">
              <a
                href="#features-section"
                className="text-base font-bold text-red-500 hover:text-white dark:hover:text-white"
                onClick={(e) => {
                  e.preventDefault();

                  const section = document.getElementById("features-section");
                  if (section) {
                    section.scrollIntoView({ behavior: "smooth" });
                  }
                }}
              >
                {t("front.navbar.features.title")}
              </a>
              <ul className="mt-4 space-y-2">
                {Array.from({ length: 9 }, (_, i) => {
                  const featureKey = `dropdown${i + 1}`;
                  const featureName = t(`front.navbar.features.${featureKey}`);
                  const path = `/features/${getEnglishPath(
                    featureNameMapping,
                    featureName,
                  )}`;
                  return (
                    <li key={featureKey}>
                      <Link
                        to={path}
                        className="text-base text-gray-300 hover:text-white dark:hover:text-white"
                      >
                        {featureName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="px-5 py-2">
              <p className="text-base font-bold text-red-500">
                {t("front.navbar.business.title")}
              </p>
              <ul className="mt-4 space-y-2">
                {Array.from({ length: 6 }, (_, i) => {
                  const businessKey = `dropdown${i + 1}`;
                  const businessName = t(
                    `front.navbar.business.${businessKey}`,
                  );
                  const path = `/business/${getEnglishPath(
                    businessNameMapping,
                    businessName,
                  )}`;
                  return (
                    <li key={businessKey}>
                      <Link
                        to={path}
                        className="text-base text-gray-300 hover:text-white dark:hover:text-white"
                      >
                        {businessName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="px-5 py-2">
              <p className="text-base font-bold text-red-500">
                {t("front.navbar.pricing.title")}
              </p>
              <ul className="mt-4 space-y-2">
                {Array.from({ length: 4 }, (_, i) => {
                  const pricingKey = `dropdown${i + 1}`;
                  const pricingName = t(`front.navbar.pricing.${pricingKey}`);
                  const path = `/pricing`;
                  return (
                    <li key={pricingKey}>
                      <Link
                        to={path}
                        className="text-base text-gray-300 hover:text-white dark:hover:text-white"
                      >
                        {pricingName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="px-5 py-2">
              <p className="text-base font-bold text-red-500">
                {t("front.navbar.company.title")}
              </p>
              <ul className="mt-4 space-y-2">
                {Array.from({ length: 4 }, (_, i) => {
                  const companyKey = `dropdown${i + 1}`;
                  const companyName = t(`front.navbar.company.${companyKey}`);

                  let path;
                  if (
                    getEnglishPath(companyNameMapping, companyName) ===
                    "contact"
                  ) {
                    path = "/contact";
                  } else {
                    path = `/company/${getEnglishPath(
                      companyNameMapping,
                      companyName,
                    )}`;
                  }

                  return (
                    <li key={companyKey}>
                      <Link
                        to={path}
                        className="text-base text-gray-300 hover:text-white dark:hover:text-white"
                      >
                        {companyName}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="px-5 py-2">
              <p className="text-base font-bold text-red-500">
                {t("front.navbar.moreinfor")}
              </p>
              <ul className="mt-4 space-y-2">
                <li>
                  <Link
                    to="/terms-and-conditions"
                    className="text-base text-gray-300 hover:text-white dark:hover:text-white"
                  >
                    {t("front.navbar.terms")}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/privacy-policy"
                    className="text-base text-gray-300 hover:text-white dark:hover:text-white"
                  >
                    {t("front.navbar.privacy")}
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
          <div className="mt-8 flex justify-center space-x-6">
            <a
              href="https://www.facebook.com/"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Facebook</span>
              <FontAwesomeIcon icon={faSquareFacebook} className="h-6 w-6" />
            </a>

            <a
              href="https://www.instagram.com/"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Instagram</span>
              <FontAwesomeIcon icon={faSquareInstagram} className="h-6 w-6" />
            </a>

            <a
              href="https://twitter.com/zhaoyuWu8"
              className="text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Twitter</span>
              <FontAwesomeIcon icon={faSquareXTwitter} className="h-6 w-6" />
            </a>
          </div>
          <p className="mt-2 text-center text-xs text-gray-400">
            {t("front.footer.copyright", { year: new Date().getFullYear() })}
          </p>
        </div>
      </footer>
    </div>
  );
}
