import React, { CSSProperties, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import img1 from "@/assets/img/swiper1.png";
import img2 from "@/assets/img/swiper7.png";
import img3 from "@/assets/img/swiper5.png";
import img4 from "@/assets/img/swiper2.png";
import img5 from "@/assets/img/swiper3.png";
import img6 from "@/assets/img/swiper4.png";

import { useTranslation } from "react-i18next";

interface TestimonialFeatureProps {
  imgSrc: string;
  featureTitle: string;
  featureDescription: string;
  highlight: boolean;
  buttonText: string;
}

const TestimonialFeature: React.FC<TestimonialFeatureProps> = ({
  imgSrc,
  featureTitle,
  featureDescription,
  highlight,
  buttonText,
}) => {
  const isSmallScreen = window.innerWidth < 768;
  const mobileStyles = {
    title: {
      textAlign: isSmallScreen ? ("center" as const) : ("left" as const),
    },
    description: {
      maxWidth: isSmallScreen ? "100%" : "100%",
      margin: isSmallScreen ? "0 auto" : "0",
      wordWrap: "break-word" as CSSProperties["wordWrap"],
      whiteSpace: "normal" as CSSProperties["whiteSpace"],
      fontSize: isSmallScreen ? "0.9em" : "1em",
      height: "5em",
      overflow: "hidden",
    },
  };

  const featureStyle: React.CSSProperties = {
    margin: isSmallScreen ? "20px" : "0",
    maxWidth: isSmallScreen ? "80%" : "95%",
  };

  const imageStyle: React.CSSProperties = {
    maxWidth: isSmallScreen ? "150px" : "100%",
    height: "auto",
    objectFit: "cover",
    display: "block",
    margin: "0 auto",
    marginBottom: isSmallScreen ? "10px" : "15px",
  };

  return (
    <div
      style={featureStyle}
      className={`flex flex-col items-center mb-6 p-4 rounded-lg ${
        highlight ? "bg-yellow-100" : "bg-white"
      }`}
    >
      <img
        src={imgSrc}
        alt={featureTitle}
        style={imageStyle}
        className="mb-4 rounded-lg self-start"
      />
      <h3
        style={mobileStyles.title}
        className="text-red-500 text-xl font-bold mb-2 self-start text-left w-full"
      >
        {featureTitle}
      </h3>
      <p
        style={mobileStyles.description}
        className="text-gray-600 mb-4 self-start text-left w-full"
      >
        {featureDescription}
      </p>
      <div className="w-full flex justify-center">
        <button className="text-red-500 font-bold flex items-center">
          {buttonText}
          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
        </button>
      </div>
    </div>
  );
};

const TestimonialsFeature: React.FC = () => {
  const { t } = useTranslation();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = [img1, img2, img3, img4, img5, img6];
  const titles = images.map((_, index) =>
    t(`front.company.testimonials.feature${index + 1}.title`),
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const highlightFeature = (title: string): boolean => {
    return (
      searchTerm !== "" &&
      title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  const buttonText = t("front.company.testimonials.button");

  const styles: Record<string, CSSProperties> = {
    title: {
      fontSize: isMobile ? "2em" : "3em",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "0.5em",
    },
    featureContainer: {
      display: isMobile ? "flex" : "grid",
      overflowX: isMobile ? "scroll" : "visible",
      gridTemplateColumns: isMobile ? "none" : "repeat(3, 1fr)",
      padding: isMobile ? "0 10px" : "0",
      width: isMobile ? "auto" : "100%",
      whiteSpace: isMobile ? "nowrap" : "normal",
    },
  };

  return (
    <div className="py-8 bg-white mt-40">
      <div className="text-center">
        <h2 style={styles.title}>{t("front.company.testimonials.title")}</h2>
        <p className="text-lg mb-4 w-3/4 mx-auto">
          {t("front.company.testimonials.description")}
        </p>
        <div className="inline-block w-full md:w-3/4 lg:w-1/2 px-3 mb-6">
          <div className="relative rounded-full shadow-sm mt-10 mb-10">
            <input
              className="pl-10 pr-3 py-2 w-full rounded-full border border-gray-300 focus:border-red-500 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              type="text"
              placeholder={t("front.company.testimonials.searchPlaceholder")}
              onChange={handleSearchChange}
            />
            <div className="absolute left-0 top-0 ml-4 mt-2.5">
              <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div style={styles.featureContainer}>
          {images.map((imgSrc, index) => (
            <TestimonialFeature
              key={index}
              imgSrc={imgSrc}
              featureTitle={titles[index]}
              featureDescription={t(
                `front.company.testimonials.feature${index + 1}.description`,
              )}
              buttonText={buttonText}
              highlight={highlightFeature(titles[index])}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialsFeature;
