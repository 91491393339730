import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import posImage from "@/assets/img/features-pos.png";
import handhelds from "@/assets/img/features-handhelds.png";
import payroll from "@/assets/img/features-payroll.png";
import kiosk from "@/assets/img/features-kiosk.png";
import loyaltyProgram from "@/assets/img/features-loyalty-program.png";
import waitlistReservation from "@/assets/img/features-waitlist-reservation.png";
import payment from "@/assets/img/features-payments.png";
import onlineOrdering from "@/assets/img/features-online-ordering.png";
import integrations from "@/assets/img/features-integrations.png";
import swiper1 from "@/assets/img/swiper1.png";
import swiper2 from "@/assets/img/swiper2.png";
import swiper3 from "@/assets/img/swiper3.png";
import swiper4 from "@/assets/img/swiper4.png";
import swiper5 from "@/assets/img/swiper5.png";
import swiper6 from "@/assets/img/swiper6.png";
import swiper7 from "@/assets/img/swiper7.png";
import {
  faDesktop,
  faMobileScreen,
  faCommentsDollar,
  faCartShopping,
  faStar,
  faUsers,
  faListCheck,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";

import "@/assets/css/swiper.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
interface Props {
  className: string;
  id?: string;
}
interface FeatureSlide {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
}

interface SwiperProps {
  slides: FeatureSlide[];
  startSlide: number;
  onPrev: () => void;
  onNext: () => void;
}

const Swiper: React.FC<SwiperProps> = ({
  slides,
  startSlide,
  onPrev,
  onNext,
}) => {
  const translateValue = -startSlide * 28;
  const isSmallScreen = window.innerWidth < 768;

  return (
    <div className="swiper-container flex overflow-x-auto sm:block">
      <div
        className="swiper-slides-wrapper flex"
        style={{
          transform: `translateX(${translateValue}%)`,
          transition: "transform 0.5s ease",
        }}
      >
        {slides.map((slide, index) => (
          <div
            key={slide.id}
            className="swiper-feature-box flex-shrink-0"
            style={isSmallScreen ? { width: "250px" } : {}}
          >
            <img src={slide.imageUrl} alt={slide.title} />
            <div className="slide-content">
              <h3>{slide.title}</h3>
              <p>{slide.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default function Features({ id, className }: Props) {
  const { t } = useTranslation();

  const [startSlide, setStartSlide] = useState(0);
  const isSmallScreen = window.innerWidth < 768;

  const smallScreenStyles = {
    maxWidth: "320px",
    maxHeight: "420px",
    margin: "2 auto",
    marginTop: "3rem",
    marginBottom: "3rem",
    marginLeft: "auto",
    marginRight: "auto",
  };

  const nextSlide = () => {
    if (startSlide < features.length - 4) {
      setStartSlide(startSlide + 1);
    }
  };

  const prevSlide = () => {
    if (startSlide > 0) {
      setStartSlide(startSlide - 1);
    }
  };

  const features = [
    {
      id: 1,
      title: t("front.features.testimonials.clients.bethany.title"),
      description: t("front.features.testimonials.clients.bethany.description"),
      imageUrl: swiper1,
    },
    {
      id: 2,
      title: t("front.features.testimonials.clients.dongwang.title"),
      description: t(
        "front.features.testimonials.clients.dongwang.description",
      ),
      imageUrl: swiper2,
    },
    {
      id: 3,
      title: t("front.features.testimonials.clients.hbbq.title"),
      description: t("front.features.testimonials.clients.hbbq.description"),
      imageUrl: swiper3,
    },
    {
      id: 4,
      title: t("front.features.testimonials.clients.wanastu.title"),
      description: t("front.features.testimonials.clients.wanastu.description"),
      imageUrl: swiper4,
    },
    {
      id: 5,
      title: t("front.features.testimonials.clients.jingarden.title"),
      description: t(
        "front.features.testimonials.clients.jingarden.description",
      ),
      imageUrl: swiper5,
    },
    {
      id: 6,
      title: t("front.features.testimonials.clients.mingplace.title"),
      description: t(
        "front.features.testimonials.clients.mingplace.description",
      ),
      imageUrl: swiper6,
    },
    {
      id: 7,
      title: t("front.features.testimonials.clients.winhing.title"),
      description: t("front.features.testimonials.clients.winhing.description"),
      imageUrl: swiper7,
    },
  ];

  return (
    <div className={className}>
      <section id={id}>
        <div className="mx-auto py-2 md:py-24 px-5 sm:space-y-12">
          <div className="space-y-5 text-center sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <p className="mt-2 text-4xl md:text-7xl font-extrabold text-red-600">
              {t("front.features.frontend.title")}
            </p>
            <h2 className="font-bold text-black uppercase tracking-wide text-lg md:text-4xl dark:text-white">
              {t("front.features.frontend.hint")}
            </h2>
            <p className="mt-4 text-lg md:text-lg text-gray-500">
              {t("front.features.frontend.headline")}
            </p>
          </div>
          <div className="features-section gap-12 flex sm:grid lg:grid-cols-3 overflow-x-auto sm:overflow-x-hidden">
            <a
              href="/features/pos"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faDesktop}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.pos.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="POS"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={posImage}
                  />
                  <div>{t("front.features.frontend.pos.description")} </div>
                </dd>
              </div>
            </a>

            <a
              href="/features/handhelds"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faMobileScreen}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.handhelds.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Router"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={handhelds}
                  />
                  <div>
                    {t("front.features.frontend.handhelds.description")}
                  </div>
                </dd>
              </div>
            </a>

            <a
              href="/features/kiosks"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faCartShopping}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.kiosk.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Online Order & Delivery"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={kiosk}
                  />
                  <div>{t("front.features.frontend.kiosk.description")}</div>
                </dd>
              </div>
            </a>

            <a
              href="/features/onlineorderingdelivery"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faListCheck}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.online.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Inventory Management"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={onlineOrdering}
                  />
                  <div>{t("front.features.frontend.online.description")}</div>
                </dd>
              </div>
            </a>

            <a
              href="/features/loyaltyprogram"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faStar}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.loyaltyProgram.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Loyalty Program"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={loyaltyProgram}
                  />
                  <div>
                    {t("front.features.frontend.loyaltyProgram.description")}
                  </div>
                </dd>
              </div>
            </a>

            <a
              href="/features/waitlistreservations"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faUsers}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.waitlistReservation.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Waitlist & Reservation"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={waitlistReservation}
                  />
                  <div>
                    {t(
                      "front.features.frontend.waitlistReservation.description",
                    )}
                  </div>
                </dd>
              </div>
            </a>

            <a
              href="/features/paymentssecurity"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faCreditCard}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.payments.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Payments"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={payment}
                  />
                  <div>{t("front.features.frontend.payments.description")}</div>
                </dd>
              </div>
            </a>

            <a
              href="/features/payrollteammanagement"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faCommentsDollar}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.payrollManagement.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Payroll Management"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={payroll}
                  />
                  <div>
                    {t("front.features.frontend.payrollManagement.description")}
                  </div>
                </dd>
              </div>
            </a>

            <a
              href="/features/redbirdintegrations"
              className="block hover:no-underline flex-shrink-0"
              style={isSmallScreen ? smallScreenStyles : {}}
            >
              <div className="flex h-full flex-col rounded-lg bg-gray-100 p-8 dark:bg-gray-800">
                <dt style={{ display: "flex", alignItems: "center" }}>
                  <FontAwesomeIcon
                    icon={faChartLine}
                    style={{
                      color: "red",
                      width: "24px",
                      height: "24px",
                      marginRight: "8px",
                    }}
                  />
                  <p
                    className="text-lg leading-6 font-medium text-red-500"
                    style={{ fontWeight: "bold" }}
                  >
                    {t("front.features.frontend.integrations.title")}
                  </p>
                </dt>
                <dd className="mt-2 ml-9 text-base text-gray-500 space-y-3">
                  <img
                    alt="Sales Analytics"
                    className="w-full h-55 shadow-lg border border-gray-200 dark:border-slate-800 rounded-md object-cover"
                    src={integrations}
                  />
                  <div>
                    {t("front.features.frontend.integrations.description")}{" "}
                  </div>
                </dd>
              </div>
            </a>
          </div>
        </div>
      </section>

      <section className="features-section2 bg-red-100">
        <div className="container mx-auto py-24 px-5 sm:space-y-12">
          <div className="space-y-5 text-center sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <p className="mt-2 text-4xl md:text-6xl font-extrabold dark:text-black">
              {t("front.features.testimonials.title")}
            </p>
            <p className="mt-4 text-lg text-gray-500">
              {t("front.features.testimonials.description")}
            </p>
          </div>
          <div className="mt-12 lg:mt-0 lg:col-span-2 ">
            <div className="swiper-outer-container ">
              <button
                className="swiper-outer-button swiper-button-prev hidden md:block"
                onClick={prevSlide}
                disabled={startSlide === 0}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <div className="swiper-container flex overflow-x-auto">
                <Swiper
                  slides={features}
                  startSlide={startSlide}
                  onPrev={prevSlide}
                  onNext={nextSlide}
                />
              </div>
              <button
                className="swiper-outer-button swiper-button-next hidden md:block"
                onClick={nextSlide}
                disabled={startSlide >= features.length - 4}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
