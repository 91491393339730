import React, { useCallback, useState } from "react";
import ProductModal from "@/components/front/ProductModal";
import "@/assets/css/ProductCard.css";

interface ProductCardProps {
  product: any;
}

const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <div
      className="product-card border cursor-pointer transition-shadow duration-200 hover:shadow-xl mx-auto"
      onClick={openModal}
    >
      <div className="product-content">
        <div className="product-info">
          <h2 className="product-name">{product.name}</h2>
          <p className="product-description">{product.description}</p>
          <div className="product-price mt-auto">
            <span>
              ${product.unit_price === "0.00" ? "Custom" : product.unit_price}
            </span>
          </div>
        </div>
        <div className="product-image-wrapper">
          <div className="product-image-container">
            <img
              src={product.image}
              alt={product.name}
              className="product-image"
            />
            <button className="add-to-cart-button">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      {isModalOpen && <ProductModal product={product} onClose={closeModal} />}
    </div>
  );
};

export default ProductCard;
