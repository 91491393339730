import PageLoader from "@/components/back/Spinner";
import LocaleSelector from "@/components/ui/selectors/LocaleSelector";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faBell,
  faCaretDown,
  faEnvelope,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface NavbarProps {
  onMenuItemClick: (componentName: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ onMenuItemClick }) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [ownerAvatar, setOwnerAvatar] = useState<string | null>(null);
  const { user, logout } = useUser();

  useEffect(() => {
    const fetchOwnerAvatar = async () => {
      try {
        if (!loading && axiosInstance && user?.owner) {
          const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_OWNER;
          if (!apiUrl) {
            console.error("API URL is not defined.");
            return;
          }
          const response = await axiosInstance.get(
            `${apiUrl}${user.owner.id}/`,
          );
          setOwnerAvatar(response.data.avatar);
        }
      } catch (error) {
        console.error("Error fetching owner avatar:", error);
      }
    };

    fetchOwnerAvatar();
  }, [axiosInstance, loading, user]);

  const showMenu = () => {
    if (timeoutId) clearTimeout(timeoutId);
    setShowDropdown(true);
  };

  const hideMenu = () => {
    const id = setTimeout(() => {
      setShowDropdown(false);
    }, 300);
    setTimeoutId(id);
  };

  const handleMenuItemClick = (componentName: string) => {
    onMenuItemClick(componentName);
    setShowDropdown(false);
  };

  const handleLogout = () => {
    logout(); //
    window.location.href = "/login";
  };

  const handleSupportClick = () => {
    onMenuItemClick("support");
  };

  const styles: Record<string, React.CSSProperties> = {
    navbar: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F9F9FC",
      padding: "16px",
      boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    },
    iconButton: {
      margin: "0 12px",
    },
    dropdownContainer: {
      position: "relative",
      margin: "0 8px",
    },
    dropdownButton: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    dropdownMenu: {
      position: "absolute",
      right: "0",
      marginTop: "8px",
      padding: "8px 0",
      width: "150px",
      backgroundColor: "#fff",
      borderRadius: "4px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      zIndex: 20,
      textAlign: "right",
    },
    dropdownItem: {
      cursor: "pointer",
      width: "100%",
      display: "block",
      padding: "8px 16px",
      fontSize: "14px",
      color: "#4a4a4a",
      textDecoration: "none",
      border: "none",
      background: "none",
      backgroundColor: "transparent",
      textAlign: "right",
    },
    dropdownItemHover: {
      backgroundColor: "#f2f2f2",
    },
    avatar: {
      height: "40px",
      width: "40px",
      borderRadius: "50%",
      marginRight: "8px",
    },
    caretIcon: {
      marginLeft: "8px",
    },
  };

  const [hoveredItem, setHoveredItem] = useState<string | null>(null);

  return (
    <PageLoader isFetching={isFetching}>
      <nav style={styles.navbar}>
        <div style={{ display: "flex", alignItems: "center" }}></div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="hidden lg:flex">
            <button onClick={handleSupportClick}>
              <FontAwesomeIcon icon={faEnvelope} style={styles.iconButton} />

              {/* <FontAwesomeIcon icon={faBell} style={styles.iconButton} /> */}
            </button>
          </div>
          <div className="hidden lg:flex">
            <LocaleSelector className="px-2" />
          </div>
          <div style={styles.dropdownContainer}>
            <div
              onMouseEnter={showMenu}
              onMouseLeave={hideMenu}
              style={styles.dropdownButton}
            >
              {ownerAvatar ? (
                <img
                  src={ownerAvatar}
                  alt="Owner Avatar"
                  style={styles.avatar}
                />
              ) : (
                <FontAwesomeIcon icon={faUserCircle} style={styles.avatar} />
              )}
              <span className="font-bold text-xl">
                {user?.first_name + " " + user?.last_name || "User"}
              </span>
              <FontAwesomeIcon icon={faCaretDown} style={styles.caretIcon} />
            </div>

            {showDropdown && (
              <div
                onMouseEnter={showMenu}
                onMouseLeave={hideMenu}
                style={styles.dropdownMenu}
              >
                <button
                  onMouseEnter={() => setHoveredItem("setting")}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={() => handleMenuItemClick("setting")}
                  style={
                    hoveredItem === "dashboard"
                      ? { ...styles.dropdownItem, ...styles.dropdownItemHover }
                      : styles.dropdownItem
                  }
                >
                  {t("back.management.navbar.dropdown.profile")}
                </button>
                <button
                  onMouseEnter={() => setHoveredItem("setting")}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={() => handleMenuItemClick("setting")}
                  style={
                    hoveredItem === "settings"
                      ? { ...styles.dropdownItem, ...styles.dropdownItemHover }
                      : styles.dropdownItem
                  }
                >
                  {t("back.management.navbar.dropdown.settings")}
                </button>
                <button
                  onMouseEnter={() => setHoveredItem("logout")}
                  onMouseLeave={() => setHoveredItem(null)}
                  onClick={handleLogout}
                  style={
                    hoveredItem === "logout"
                      ? { ...styles.dropdownItem, ...styles.dropdownItemHover }
                      : styles.dropdownItem
                  }
                >
                  {t("back.management.navbar.dropdown.logout")}
                </button>
              </div>
            )}
          </div>
        </div>
      </nav>
    </PageLoader>
  );
};

export default Navbar;
