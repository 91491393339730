// src/components/PageHeader.tsx
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faPlus } from "@fortawesome/free-solid-svg-icons";

interface PageHeaderProps {
  title: string;
  exportLabel: string;
  addLabel: string;
  onExport: () => void;
  onAdd: () => void;
}

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  exportLabel,
  addLabel,
  onExport,
  onAdd,
}) => {
  return (
    <>
      {/* Desktop Layout */}
      <div className="desktop-layout">
        <div className="flex justify-between items-center mb-4">
          <h1 className="text-2xl font-semibold">{title}</h1>
          <div>
            <button
              onClick={onExport}
              className="mr-2 px-4 py-2 bg-gray-200 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              {exportLabel}
            </button>
            <button
              onClick={onAdd}
              className="px-4 py-2 bg-black text-white rounded-md font-bold shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {addLabel}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Layout */}
      <div className="mobile-layout">
        <h1 className="text-xl font-semibold mb-4">{title}</h1>
        <div className="flex justify-between items-center mb-4">
          <div>
            <button
              onClick={onExport}
              className="mr-2 px-2 py-1 bg-gray-200 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
            >
              <FontAwesomeIcon icon={faFileExport} className="mr-2" />
              {exportLabel}
            </button>
            <button
              onClick={onAdd}
              className="px-2 py-1 bg-black text-white rounded-md font-bold shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {addLabel}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageHeader;
