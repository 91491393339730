import useAbortableEffect, { statusType } from "@/utils/abortableEffect";
import classNames from "@/utils/shared/ClassesUtils";
import { useState } from "react";
import {
  RiArrowLeftDoubleFill,
  RiArrowLeftSFill,
  RiArrowRightDoubleFill,
  RiArrowRightSFill,
} from "react-icons/ri";

interface PaginationProps {
  data: { totalCount: number };
  onChange: (page: number, rowsPerPage: number) => void;
  defaultPerPage: number;
  cPage: number;
  className?: string;
}
const Pagination = ({
  className = "mx-auto my-4",
  data,
  onChange,
  defaultPerPage,
  cPage,
}: PaginationProps) => {
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);

  useAbortableEffect(
    (status: statusType) => {
      if (!status.aborted) {
        if (defaultPerPage) {
          setRowsPerPage(defaultPerPage);
        }
        if (cPage) {
          setCurrentPage(parseInt(`${cPage}`));
        }
      }
    },
    [defaultPerPage, cPage],
  );

  const getPageNumbers = () => {
    const totalPage = Math.ceil(data.totalCount / rowsPerPage);
    if (totalPage === 0) return [1];

    const pageNumbers = [];

    if (currentPage === 1 && currentPage === totalPage) {
      pageNumbers.push(currentPage);
    } else if (currentPage === totalPage) {
      let tempPage = currentPage;
      let pageLimit = 3;
      while (tempPage >= 1 && pageLimit > 0) {
        pageNumbers.push(tempPage);
        tempPage--;
        pageLimit--;
      }
    } else {
      pageNumbers.push(currentPage);
      if (currentPage > 1) {
        pageNumbers.push(currentPage - 1);
        if (currentPage + 1 <= totalPage) {
          pageNumbers.push(currentPage + 1);
        }
      } else {
        pageNumbers.push(currentPage + 1);
        if (currentPage + 2 <= totalPage) {
          pageNumbers.push(currentPage + 2);
        }
      }
    }
    return pageNumbers.sort((a, b) => a - b);
  };

  const totalCount = data.totalCount;
  if (!totalCount) {
    return null;
  }
  const totalPage = Math.ceil(totalCount / rowsPerPage);
  const pageNumbers = getPageNumbers();

  const goToPage = (page: number) => {
    setCurrentPage(page);
    onChange(page, rowsPerPage);
    const pageContainer = window.document.getElementById("pages");
    pageContainer?.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className={className}>
      <nav className="flex flex-1 justify-between md:justify-center md:space-x-2">
        <NavButton
          id="first-page"
          icon
          onClick={() => goToPage(1)}
          disabled={currentPage === 1}
          children={<RiArrowLeftDoubleFill />}
        />
        <NavButton
          id="prev-pages"
          icon
          onClick={() => goToPage(currentPage - 1)}
          disabled={currentPage - 1 <= 0}
          children={<RiArrowLeftSFill />}
        />

        {pageNumbers.map((page) => (
          <NavButton
            id={`page-${page}`}
            key={page}
            onClick={() => goToPage(page)}
            selected={currentPage === page}
          >
            {page}
          </NavButton>
        ))}

        <NavButton
          id="next-pages"
          icon
          children={<RiArrowRightSFill />}
          onClick={() => goToPage(currentPage + 1)}
          disabled={currentPage + 1 > totalPage}
        />
        <NavButton
          id="last-page"
          icon
          children={<RiArrowRightDoubleFill />}
          onClick={() => goToPage(totalPage)}
          disabled={totalPage === 0 || currentPage === totalPage}
        />
      </nav>
    </div>
  );
};

export default Pagination;

interface NavButtonProps {
  id?: string;
  onClick: () => void;
  children: React.ReactNode;
  disabled?: boolean | undefined;
  selected?: boolean | undefined;
  icon?: boolean;
}

const NavButton = (props: NavButtonProps) => {
  return (
    <button
      id={props.id}
      disabled={props.disabled}
      onClick={props.onClick}
      className={classNames(
        "px-4 py-2 rounded-md font-medium",
        props.icon
          ? "h-10 w-10 text-xl disabled:opacity-50"
          : "bg-red-600 text-white hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500",
      )}
    >
      {props.children}
    </button>
  );
};
