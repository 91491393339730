import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import PrintableOrderDetails from "./PrintOrderDetails";
import { useTranslation } from "react-i18next";

interface OrderDetailsProps {
  isOpen: boolean;
  onClose: () => void;
  order: any;
  onStatusChange: (status: string) => void;
  children?: React.ReactNode;
}

const OrderDetails: React.FC<OrderDetailsProps> = ({
  isOpen,
  onClose,
  order,
  onStatusChange,
  children,
}) => {
  const componentRef = useRef(null);
  const { t } = useTranslation();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  if (!isOpen) return null;

  const handleStatusChangeAndClose = (status: string) => {
    onStatusChange(status);
    onClose();
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div
        className="fixed inset-0 bg-black bg-opacity-50"
        onClick={onClose}
      ></div>
      <div className="bg-white p-6 rounded-lg z-10 max-w-lg w-full relative">
        <button
          onClick={onClose}
          className="absolute top-0 right-0 p-4 text-gray-500 hover:text-gray-700"
        >
          &times;
        </button>
        <h2 className="text-xl font-bold mb-4">
          {t("back.management.order.orderdetail")}
        </h2>
        <div className="max-h-64 overflow-y-auto">
          <div ref={componentRef}>
            <PrintableOrderDetails order={order} />
          </div>
        </div>
        <div className="mt-4">
          <label
            htmlFor="status"
            className="block text-sm font-medium text-gray-700"
          >
            {t("back.management.order.changeStatus")}
          </label>
          <select
            id="status"
            name="status"
            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
            value={order.fulfillment_status}
            onChange={(e) => onStatusChange(e.target.value)}
          >
            <option value="Preparing">
              {t("back.management.order.preparing")}
            </option>
            <option value="Completed">
              {t("back.management.order.complete")}
            </option>
            <option value="Cancelled">
              {t("back.management.order.cancel")}
            </option>
          </select>
        </div>
        <div className="mt-4 flex justify-end">
          <button
            onClick={() => handleStatusChangeAndClose(order.fulfillment_status)}
            className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 mr-2"
          >
            {t("back.management.order.changeStatus")}
          </button>
          <button
            onClick={handlePrint}
            className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600"
          >
            {t("back.management.order.print")}
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default OrderDetails;
