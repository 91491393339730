import React, { useState } from "react";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useTranslation } from "react-i18next";

interface Order {
  id: number;
  created_at: string;
  total_price: string;
}

interface RefundPopupProps {
  order: Order;
  onClose: () => void;
  onRefundComplete: () => void;
}

const RefundPopup: React.FC<RefundPopupProps> = ({
  order,
  onClose,
  onRefundComplete,
}) => {
  const [refundAmount, setRefundAmount] = useState<string>("");
  const { t } = useTranslation();
  const [isRefundLoading, setIsRefundLoading] = useState(false);
  const [axiosInstance] = useAxiosInstance();
  const isWithin24Hours = (created_at: string) => {
    const orderDate = new Date(created_at);
    const now = new Date();
    const diffInMs = now.getTime() - orderDate.getTime();
    return diffInMs <= 24 * 60 * 60 * 1000;
  };

  const handleRefundAmountChange = (value: string) => {
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value)) {
      setRefundAmount(value);
    }
  };

  const handleRefund = async () => {
    setIsRefundLoading(true);
    try {
      const refundData = {
        order_id: order.id,
        amount: isWithin24Hours(order.created_at)
          ? parseFloat(order.total_price)
          : parseFloat(refundAmount),
      };

      const response = await axiosInstance.post(
        `${process.env.REACT_APP_API_BASE_URL}api/refund/`,
        refundData,
      );

      if (!response.status.toString().startsWith("2")) {
        throw new Error("Failed to process refund");
      }

      onRefundComplete();
      onClose();
    } catch (error: any) {
      console.error("Error processing refund:", error);
    } finally {
      setIsRefundLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-4 max-w-lg w-full h-auto">
        {isWithin24Hours(order.created_at) ? (
          <div>
            <h2 className="font-bold text-lg overflow-wrap break-word whitespace-normal max-w-full text-center">
              {t("back.management.order.void")}
            </h2>

            <div className="mt-4 flex justify-end">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded mr-2 h-12"
                onClick={handleRefund}
                disabled={isRefundLoading}
              >
                {isRefundLoading
                  ? t("back.management.order.processing")
                  : t("back.management.order.confirm")}
              </button>
              <button
                className="bg-gray-400 text-white py-2 px-4 rounded h-12"
                onClick={onClose}
              >
                {t("back.management.order.cancel")}
              </button>
            </div>
          </div>
        ) : (
          <div>
            <h2 className="font-bold text-lg overflow-wrap break-word whitespace-normal max-w-full text-center">
              {t("back.management.order.enterRefundNumber")}
            </h2>
            <input
              type="text"
              className="py-2 px-4 border rounded w-full mt-2"
              placeholder={t("back.management.order.refundAmount")}
              value={refundAmount}
              onChange={(e) => handleRefundAmountChange(e.target.value)}
            />
            <div className="mt-4 flex justify-end">
              <button
                className="bg-red-500 text-white py-2 px-4 rounded mr-2 h-12"
                onClick={handleRefund}
                disabled={
                  !refundAmount ||
                  parseFloat(refundAmount) > parseFloat(order.total_price) ||
                  isRefundLoading
                }
              >
                {isRefundLoading
                  ? t("back.management.order.processing")
                  : t("back.management.order.confirm")}
              </button>
              <button
                className="bg-gray-400 text-white py-2 px-4 rounded h-12"
                onClick={onClose}
              >
                {t("back.management.order.cancel")}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default RefundPopup;
