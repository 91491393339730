import PageLoader from "@/components/back/Spinner";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faEdit, faSearch, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PageHeader from "@/components/back/PageHeader";
import DataTable, { Column } from "@/components/back/DataTable";
import DeleteConfirmationDialog from "@/components/back/DeleteConfirmationDialog";

interface Device {
  id: number;
  name: string;
  device_number: string;
  status: string;
  mode: string;
  created_at: string;
  is_primary: boolean;
}

interface SortState {
  field: keyof Device;
  direction: "asc" | "desc";
}

interface DevicesTableProps {
  onMenuItemClick: (
    componentName: string,
    successMessage?: string,
    selectedItemId?: number,
  ) => void;
  successMessage: string;
}

export default function DevicesTable({
  onMenuItemClick,
  successMessage,
}: DevicesTableProps) {
  const { t } = useTranslation();
  const itemsPerPage = 10;
  const [devices, setDevices] = useState<Device[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortState, setSortState] = useState<SortState>({
    field: "name",
    direction: "asc",
  });
  const { user } = useUser();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState<number | null>(null);

  const columns: Column<Device>[] = [
    {
      key: "name",
      header: t("back.management.business.device.table.name"),
      sortable: true,
      bold: true,
    },
    {
      key: "device_number",
      header: t("back.management.business.device.table.deviceNumber"),
      sortable: true,
      bold: true,
    },
    {
      key: "status",
      header: t("back.management.business.device.table.status"),
      sortable: true,
      bold: true,
    },
    {
      key: "mode",
      header: t("back.management.business.device.table.mode"),
      sortable: true,
      bold: true,
    },
    {
      key: "is_primary",
      header: t("back.management.business.device.table.isPrimary"),
      sortable: true,
      bold: true,
    },
    {
      key: "action",
      header: t("back.management.menu.product.table.action"),
      render: (device: Device) => (
        <>
          <button onClick={() => editItem(device.id)} className="mr-2">
            <FontAwesomeIcon
              icon={faEdit}
              className="text-yellow-600 hover:text-yellow-800"
            />
          </button>
          <button onClick={() => openDeleteConfirmation(device.id)}>
            <FontAwesomeIcon
              icon={faTrash}
              className="text-red-600 hover:text-red-800"
            />
          </button>
        </>
      ),
    },
  ];

  const getStatusColor = (device: Device) => {
    return device.status === "Active" ? "bg-green-500" : "bg-gray-500";
  };

  const renderMobileCard = (device: Device) => (
    <div className="flex items-center">
      <div className="flex flex-col">
        <p className="font-bold text-gray-900">{device.name}</p>
        <p className="text-gray-700">Device#: {device.device_number}</p>
        <p className="font-bold text-gray-900">
          Primary: {device.is_primary ? "Yes" : "No"}
        </p>
      </div>
      <div
        className={`absolute top-0 bottom-0 left-0 w-1.5 ${getStatusColor(
          device,
        )}`}
      ></div>
    </div>
  );

  const fetchDevices = useCallback(async () => {
    try {
      if (!loading && axiosInstance && user?.selectedBranch) {
        const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_DEVICES ?? "";
        const response = await axiosInstance.get(
          `${apiUrl}?branch=${user.selectedBranch.id}&_page=${currentPage}&_limit=${itemsPerPage}&q=${searchQuery}`,
        );
        let fetchedDevices = response.data.results;
        setDevices(fetchedDevices);
        setTotalItems(response.data.count);
      }
    } catch (error) {
      console.error("Error fetching devices:", error);
    }
  }, [loading, axiosInstance, user, currentPage, itemsPerPage, searchQuery]);

  useEffect(() => {
    if (successMessage) {
      setBannerMessage(successMessage);
      setShowBanner(true);
      setIsSuccess(true);
    }
  }, [successMessage]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      fetchDevices();
    }, 500);

    return () => clearTimeout(timerId);
  }, [fetchDevices]);

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const editItem = (id: number) => {
    onMenuItemClick("editDevice", "", id);
  };

  const openDeleteConfirmation = (id: number) => {
    setItemToDelete(id);
    setDeleteConfirmationOpen(true);
  };

  const closeDeleteConfirmation = () => {
    setItemToDelete(null);
    setDeleteConfirmationOpen(false);
  };

  const confirmDelete = async () => {
    if (itemToDelete) {
      try {
        if (!loading && axiosInstance) {
          const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_DEVICES ?? "";
          await axiosInstance.delete(`${apiUrl}${itemToDelete}`);
          fetchDevices();
          setBannerMessage(t("back.management.business.device.successDelete"));
          setIsSuccess(true);
          setShowBanner(true);
        }
      } catch (error) {
        console.error("Error deleting device:", error);
        setBannerMessage(t("back.management.business.device.failDelete"));
        setIsSuccess(false);
        setShowBanner(true);
      }
      closeDeleteConfirmation();
    }
  };

  const handleSortChange = (field: keyof Device) => {
    setSortState((prevState) => ({
      field,
      direction:
        prevState.field === field && prevState.direction === "asc"
          ? "desc"
          : "asc",
    }));
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const downloadCSV = () => {
    const csvRows = [
      [
        "ID",
        "Name",
        "Device Number",
        "Status",
        "Mode",
        "Created At",
        "Is Primary",
      ],
      ...devices.map((device) => [
        device.id,
        device.name,
        device.device_number,
        device.status,
        device.mode,
        device.created_at,
        device.is_primary ? "Yes" : "No",
      ]),
    ];

    const csvData = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "devices.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <PageLoader isFetching={isFetching}>
      <BranchRequiredWrapper>
        <div className="container mx-auto px-4 py-2">
          {showBanner && (
            <WarningBanner
              title={isSuccess ? "Success" : "Error"}
              text={bannerMessage}
              isSuccess={isSuccess}
              className={`${
                animateOut ? "animate-slideOutRight" : "animate-slideDown"
              }`}
            />
          )}
          <PageHeader
            title={t("back.management.business.device.title")}
            exportLabel={t("back.management.business.device.export")}
            addLabel={t("back.management.business.device.addDevice")}
            onExport={downloadCSV}
            onAdd={() => onMenuItemClick("addDevice")}
          />

          <DeleteConfirmationDialog
            isOpen={deleteConfirmationOpen}
            onClose={closeDeleteConfirmation}
            onConfirm={confirmDelete}
            title={t("back.management.business.device.confirmDelete")}
            message={t("back.management.business.device.confirmDeleteMessage")}
          />

          <div className="desktop-layout">
            <div className="flex justify-between items-center mb-4">
              <div className="relative flex-1 max-w-md mr-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <input
                  type="text"
                  placeholder="Search devices..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <DataTable
              data={devices}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={getStatusColor}
              renderMobileCard={renderMobileCard}
            />
          </div>

          {/* Mobile Layout */}
          <div className="mobile-layout">
            <div className="flex flex-col mb-4">
              <div className="relative flex-1 max-w-md mb-4">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                />
                <input
                  type="text"
                  placeholder="Search devices..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="pl-10 w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>

            <DataTable
              data={devices}
              columns={columns}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              totalItems={totalItems}
              sortState={sortState}
              onSortChange={handleSortChange}
              onPageChange={handlePageChange}
              onEdit={editItem}
              onDelete={openDeleteConfirmation}
              getStatusColor={getStatusColor}
              renderMobileCard={renderMobileCard}
            />
          </div>
        </div>
      </BranchRequiredWrapper>
    </PageLoader>
  );
}
