import { Switch } from "@headlessui/react";

interface FeatureState {
  delivery_feature: boolean;
  upsell_feature: boolean;
  loyalty_program_enabled: boolean;
}

interface FeatureManagementProps {
  featureState: FeatureState;
  handleChange: (name: string, value: boolean) => void;
}

const FeatureManagement: React.FC<FeatureManagementProps> = ({
  featureState,
  handleChange,
}) => {
  return (
    <div className="my-4 bg-white p-4 shadow rounded-lg">
      <h3 className="text-lg font-medium mb-2">Feature Management</h3>
      <div className="grid sm:grid-cols-1 md:grid-cols-3 space-y-4 md:space-y-0">
        <FeatureSwitch
          label="Delivery"
          checked={featureState.delivery_feature}
          onChange={(value) => handleChange("delivery_feature", value)}
        />
        <FeatureSwitch
          label="Loyalty"
          checked={featureState.loyalty_program_enabled}
          onChange={(value) => handleChange("loyalty_program_enabled", value)}
        />
        <FeatureSwitch
          label="Upsell"
          checked={featureState.upsell_feature}
          onChange={(value) => handleChange("upsell_feature", value)}
        />
      </div>
    </div>
  );
};

interface FeatureSwitchProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const FeatureSwitch: React.FC<FeatureSwitchProps> = ({
  label,
  checked,
  onChange,
}) => {
  return (
    <div className="flex items-center justify-between md:justify-around">
      <label>{label}</label>
      <Switch
        checked={checked}
        onChange={onChange}
        className={`${
          checked ? "bg-red-500" : "bg-gray-200"
        } relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
      >
        <span className="sr-only">{label}</span>
        <span
          aria-hidden="true"
          className={`${
            checked ? "translate-x-9" : "translate-x-0"
          } pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </Switch>
    </div>
  );
};

export default FeatureManagement;
