import PageLoader from "@/components/back/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import OptionListSelectionModal from "@/components/ui/modals/OptionListSelectionModal";
import { useUser } from "@/contexts/UserContext";
import { OptionList, Product } from "@/utils/types";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import {
  faCircle,
  faImage,
  faPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader } from "@/components/back/Card";

interface AddProductProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
}

const AddProduct = ({
  onMenuItemClick,
  setSuccessMessage,
}: AddProductProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [product, setProduct] = useState<Product>({
    id: 0,
    name: "",
    image: "",
    color: "#ffffff",
    description: "",
    unit_price: "",
    promotion: "",
    stock: false,
    menu_display_name: "",
    category: null,
    product_option_lists: [],
    status: "",
  });

  const [categories, setCategories] = useState<Product[]>([]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageBase64, setImageBase64] = useState<string>("");
  const [isOptionListModalOpen, setIsOptionListModalOpen] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(
    null,
  );
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);

  useEffect(() => {
    const fetchAllCategories = async () => {
      if (loading || !axiosInstance || !user?.selectedBranch) return;
      try {
        const apiUrl =
          process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
        const url = `${apiUrl}?branch=${user.selectedBranch.id}&page=1&page_size=100`;

        const response = await axiosInstance.get(url);
        setCategories(response.data.results);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchAllCategories();
  }, [axiosInstance, loading, user?.selectedBranch]);

  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void,
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  const colorOptions = [
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
    { label: "Yellow", value: "#ffff00" },
    { label: "Purple", value: "#800080" },
    { label: "Orange", value: "#ffa500" },
    { label: "Black", value: "#000000" },
  ];

  const handleColorChange = (value: string) => {
    setProduct({ ...product, color: value });
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setIsImageUploaded(true);

      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        if (typeof base64Result === "string") {
          const base64Data = base64Result.split(",")[1];
          setImageBase64(base64Data);
        }
      });
    }
  };

  const handleAddProduct = async () => {
    const transformedProductOptionLists = product.product_option_lists.map(
      (optionList) => ({
        ...optionList,
        options: optionList.options.map((option: { id: any }) => option.id),
      }),
    );

    const productData = {
      ...product,
      image: imageBase64 ? imageBase64 : undefined,
      unit_price: parseFloat(product.unit_price),
      branch: user?.selectedBranch?.id,
      sku: "1",
      category: selectedCategoryId,
      product_option_lists: transformedProductOptionLists,
      status: product.stock ? "In Stock" : "Out of Stock",
    };

    try {
      if (!loading && axiosInstance) {
        const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION;
        if (!apiUrl) {
          console.error(
            "API URL for product information is not defined in .env",
          );
          return;
        }
        await axiosInstance.post(apiUrl, productData, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        const successMessage = "Product added successfully";
        setSuccessMessage(successMessage);
        setIsSuccess(true);
        setBannerMessage(successMessage);
        setShowBanner(true);
        onMenuItemClick("product", successMessage);
      }
    } catch (error) {
      console.error("Error adding product:", error);
      setIsSuccess(false);
      setBannerMessage("Failed to add product");
      setShowBanner(true);
    }
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  const handleOptionListSave = (selectedOptionLists: OptionList[]) => {
    const updatedOptionLists = selectedOptionLists.map((optionList) => {
      const existingOptionList = product.product_option_lists.find(
        (existingList) => existingList.id === optionList.id,
      );

      return existingOptionList
        ? { ...existingOptionList, ...optionList }
        : optionList;
    });

    setProduct((prevProduct) => ({
      ...prevProduct,
      product_option_lists: updatedOptionLists,
    }));

    setIsOptionListModalOpen(false);
  };

  const handleRemoveOptionList = (id: number) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      product_option_lists: prevProduct.product_option_lists.filter(
        (optionList) => optionList.id !== id,
      ),
    }));
  };

  const handleRemoveImage = () => {
    setImagePreviewUrl("");
    setIsImageUploaded(false);
    setImageBase64("");
  };

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        <h1 className="text-xl font-bold">
          {t("back.management.menu.product.addnewproduct")}
        </h1>

        {/* General Information Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.generalinfo")}
          </h2>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="productName"
              >
                {t("back.management.menu.product.productname")}
              </label>
              <input
                type="text"
                id="name"
                placeholder={t("back.management.menu.product.enterproductname")}
                value={product.name}
                onChange={(e) =>
                  setProduct({ ...product, name: e.target.value })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
            <div className="w-full md:w-1/2 px-2">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="posMenuColor"
              >
                {t("back.management.menu.product.poscolor")}
              </label>
              <select
                id="posMenuColor"
                value={product.color}
                onChange={(e) => handleColorChange(e.target.value)}
                className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              >
                <option value="">
                  {t("back.management.menu.product.selectcolor")}
                </option>
                {colorOptions.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              {product.color && (
                <FontAwesomeIcon
                  icon={faCircle}
                  color={product.color}
                  className="mt-2"
                />
              )}
            </div>
          </div>
          <div className="flex flex-wrap -mx-2">
            <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="menu_display_name"
              >
                {t("back.management.menu.product.menuDisplayName")}
              </label>
              <input
                type="text"
                placeholder={t(
                  "back.management.menu.product.enterMenuDisplayName",
                )}
                value={product.menu_display_name}
                onChange={(e) =>
                  setProduct({ ...product, menu_display_name: e.target.value })
                }
                className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
              />
            </div>
          </div>
          <div className="mt-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="description"
            >
              {t("back.management.menu.product.description")}
            </label>
            <textarea
              id="description"
              placeholder={t("back.management.menu.product.enterdescription")}
              value={product.description}
              onChange={(e) =>
                setProduct({ ...product, description: e.target.value })
              }
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
            />
          </div>
        </div>

        {/* Media Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.media")}
          </h2>
          <p className="block text-gray-700 text-sm font-bold mt-4 mb-2">
            {t("back.management.menu.product.uploadimage")}
          </p>
          <div
            className={`block w-full border-2 ${
              isImageUploaded ? "border-solid" : "border-dotted"
            } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
          >
            <input
              id="fileUpload"
              type="file"
              className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
              onChange={handleFileChange}
            />
            {imagePreviewUrl ? (
              <>
                <img
                  src={imagePreviewUrl}
                  alt="Preview"
                  className="max-h-full max-w-full p-2"
                />
                <button
                  onClick={handleRemoveImage}
                  className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                  style={{ width: "30px", height: "30px" }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </>
            ) : (
              <div className="text-center">
                <FontAwesomeIcon
                  icon={faImage}
                  size="2x"
                  className="text-gray-400 mb-2"
                />
                <p className="text-gray-600 mb-2">
                  {t("back.management.menu.product.uploadimagehint")}
                </p>
              </div>
            )}
          </div>
        </div>

        {/* Pricing Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.pricing")}
          </h2>
          <input
            type="text"
            placeholder={t("back.management.menu.product.baseprice")}
            value={product.unit_price}
            onChange={(e) =>
              setProduct({ ...product, unit_price: e.target.value })
            }
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          />
        </div>

        {/* Inventory Section */}
        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.inventory")}
          </h2>
          <input
            type="checkbox"
            id="stock"
            checked={product.stock}
            onChange={(e) =>
              setProduct({ ...product, stock: e.target.checked })
            }
            className="mr-2"
          />
          <label htmlFor="stock" className="text-gray-700">
            Is Stock Available?
          </label>
        </div>

        <div className="my-4 bg-white p-4 shadow rounded-lg">
          <h2 className="text-lg font-semibold">
            {t("back.management.menu.product.category")}
          </h2>
          <select
            value={selectedCategoryId || ""}
            onChange={(e) =>
              setSelectedCategoryId(
                e.target.value ? parseInt(e.target.value) : null,
              )
            }
            className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
          >
            <option value="">
              {t("back.management.menu.product.selectcategory")}
            </option>
            {categories.map((cat) => (
              <option key={cat.id} value={cat.id}>
                {cat.name}
              </option>
            ))}
          </select>
        </div>

        {/* Product Option List Section */}
        <Card className="mb-4">
          <CardHeader className="flex justify-between items-center">
            <h2 className="text-lg font-semibold">{t("Linked OptionLists")}</h2>
            <button
              onClick={() => setIsOptionListModalOpen(true)}
              className="px-3 py-1 bg-gray-500 text-white rounded-md flex items-center"
            >
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              {t("Edit")}
            </button>
          </CardHeader>
          <CardContent>
            <div className="flex flex-wrap gap-2">
              {product.product_option_lists.map((optionList) => (
                <span
                  key={optionList.id}
                  className="px-2 py-1 bg-red-100 text-red-500 rounded-full text-sm flex items-center"
                >
                  {optionList.name}
                  <button
                    onClick={() => handleRemoveOptionList(optionList.id)}
                    className="ml-2 text-red-500 hover:text-red-700"
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </span>
              ))}
            </div>
          </CardContent>
        </Card>

        {/* Action Buttons */}
        <div className="flex justify-end mt-4">
          <button
            onClick={() => onMenuItemClick("product")}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("back.management.menu.product.cancelbutton")}
          </button>
          <button
            onClick={handleAddProduct}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("back.management.menu.product.confirmbutton")}
          </button>
        </div>

        <OptionListSelectionModal
          isOpen={isOptionListModalOpen}
          onClose={() => setIsOptionListModalOpen(false)}
          onSave={handleOptionListSave}
          initialSelectedOptionLists={product.product_option_lists.map(
            (optionList) => optionList.id,
          )}
        />
      </div>
    </PageLoader>
  );
};

export default AddProduct;
