import i18n from "./i18n";

const supportedLocales = [
  {
    lang: "en-US",
    name: i18n.t("shared.locales.en-US").toString(),
    flag: "us",
    lang_short: "en",
  },
  {
    lang: "zh-CN",
    name: i18n.t("shared.locales.zh-CN").toString(),
    flag: "cn",
    lang_short: "zh",
  },
];

export default supportedLocales;
