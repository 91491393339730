import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu } from "@headlessui/react";
import i18n from "@/locale/i18n";
import Dropdown from "../dropdowns/Dropdown";
import _supportedLocales from "@/locale/supportedLocales";
import classNames from "@/utils/shared/ClassesUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import LogoLight from "@/assets/img/logo-light.png";

interface Props {
  className?: string;
  btnClassName?: string;
}

export default function LocaleSelector({ className, btnClassName }: Props) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const supportedLocales = _supportedLocales;

  function select(value: string) {
    localStorage.setItem("locale", value);
    i18n.changeLanguage(value);
    setIsMobileOpen(false);
    setIsMobileOpen(false);
    setIsOpen(false);
  }

  const toggleDropdown = () => {
    if (window.innerWidth < 1025) {
      setIsMobileOpen(!isMobileOpen);
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={className}>
      <div
        className={className}
        onMouseEnter={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <Dropdown
          isOpen={isOpen}
          button={
            <span className={btnClassName} onClick={toggleDropdown}>
              <FontAwesomeIcon
                icon={faLanguage}
                aria-label={t("settings.preferences.language")}
                className="fa-2x"
              />
            </span>
          }
          options={
            <div>
              {supportedLocales.map((language, index) => (
                <Menu.Item key={index}>
                  {({ active }) => (
                    <button
                      type="button"
                      onClick={() => select(language.lang)}
                      className={classNames(
                        "w-full text-left block px-4 py-2 text-sm",
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "hover:text-red-500 hover:underline",
                      )}
                      role="menuitem"
                    >
                      {t("shared.locales." + language.lang)}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </div>
          }
        />
      </div>

      {isMobileOpen && (
        <div className={`fixed inset-0 bg-white dark:bg-gray-900 z-50`}>
          <div className="px-5 pt-4 pb-10 flex items-center justify-between">
            <div>
              <Link to="/">
                <img
                  src={LogoLight}
                  alt="Logo"
                  className="md:hidden h-14 w-auto"
                />
              </Link>
            </div>
            <button
              className="text-red-500"
              onClick={() => setIsMobileOpen(false)}
            >
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            </button>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-xl absolute left-1/2 transform -translate-x-1/2 font-medium">
              {t("settings.preferences.language")}
            </span>
          </div>
          <div className="mt-4 space-y-2">
            {supportedLocales.map((language, index) => (
              <button
                key={index}
                type="button"
                onClick={() => select(language.lang)}
                className="block w-full px-5 py-3 text-left dark:text-gray-300 mobile-menu-link "
              >
                {t("shared.locales." + language.lang)}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
