import { useCustomer } from "@/contexts/CustomerContext";
import { selectCartItemCount } from "@/features/cart/cart";
import { faShoppingCart, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

interface HeaderOnlineOrderProps {
  isSelectedBranchOpen: boolean;
}

export default function HeaderOnlineOrder({
  isSelectedBranchOpen,
}: HeaderOnlineOrderProps) {
  const itemCount = useSelector(selectCartItemCount);
  const { customer } = useCustomer();

  const cartIconClass = isSelectedBranchOpen
    ? "text-black"
    : "text-black cursor-not-allowed";

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        zIndex: 1000,
        backgroundColor: "white",
      }}
    >
      <div className="relative z-10 md:py-5 shadow-md">
        <nav
          className="relative flex items-center justify-between sm:h-10 max-w-8xl mx-auto md:px-6"
          aria-label="Global"
        >
          <div
            className="relative flex items-center justify-between md:justify-end w-full md:space-x-4 h-14 md:h-7"
            id="nav-btn"
          >
            {customer?.name ? (
              <Link
                to="/customer-profile"
                className="text-gray-500 hover:text-gray-700 h-14 md:h-7"
              >
                <span>{customer.name}</span>
              </Link>
            ) : (
              <Link
                to="/customer-login"
                className="text-gray-500 hover:text-gray-700 h-14 md:h-7 flex space-x-4 md:space-x-2 items-center"
              >
                <FontAwesomeIcon
                  icon={faUser}
                  size="1x"
                  className="-mr-2 md:-mr-0"
                />
                <span>Login/Sign Up</span>
              </Link>
            )}

            {isSelectedBranchOpen ? (
              <Link
                to="/cart"
                className="text-black h-14 md:h-7"
                style={{ position: "relative", zIndex: 1 }}
              >
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  size="2x"
                  className="-mr-2 md:mr-0"
                />
                {itemCount > 0 && (
                  <div className="absolute top-1 right-14 md:-right-2 md:-top-2 bg-white md:bg-red-600 rounded-full w-4 h-4 flex items-center justify-center text-xs text-red-600 md:text-white">
                    {itemCount}
                  </div>
                )}
              </Link>
            ) : (
              <span
                className="text-black cursor-not-allowed h-14 md:h-7"
                style={{ position: "relative", zIndex: 1 }}
              >
                <FontAwesomeIcon
                  icon={faShoppingCart}
                  size="2x"
                  className="-mr-2 md:mr-0"
                />
                {itemCount > 0 && (
                  <div className="absolute top-1 right-14 md:-right-2 md:-top-2 bg-gray-400 rounded-full w-4 h-4 flex items-center justify-center text-xs">
                    {itemCount}
                  </div>
                )}
              </span>
            )}
          </div>
        </nav>
      </div>
      <style>
        {`
          @media (max-width: 640px) {
            #nav-btn > a,
            #nav-btn > span {
              flex: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 10px 0;
              background-color: #4a4a4a;
              color: white;
              font-size: 16px;
            }
            #nav-btn > a:last-child,
            #nav-btn > span:last-child {
              background-color: #e53935;
              color: white;
              font-size: 16px;
            }
          }
        `}
      </style>
    </div>
  );
}
