import PageLoader from "@/components/back/Spinner";
import Spinner from "@/components/front/Loader/Spinner";
import WarningBanner from "@/components/ui/banners/WarningBanner";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface EditDeviceProps {
  onMenuItemClick: (componentName: string, successMessage?: string) => void;
  setSuccessMessage: (message: string) => void;
  deviceId: number | null;
}

interface Device {
  id: number;
  name: string;
  device_number: string;
  status: string;
  mode: string;
  is_primary: boolean;
  categories: number[];
  products: number[];
}

interface Category {
  id: number;
  name: string;
}

interface Product {
  id: number;
  name: string;
}

const EditDevice = ({
  onMenuItemClick,
  setSuccessMessage,
  deviceId,
}: EditDeviceProps) => {
  const { t } = useTranslation();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const { user } = useUser();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerMessage, setBannerMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [animateOut, setAnimateOut] = useState(false);
  const [device, setDevice] = useState<Device>({
    id: 0,
    name: "",
    device_number: "",
    status: "Active",
    mode: "Online",
    is_primary: false,
    categories: [],
    products: [],
  });
  const [allCategories, setAllCategories] = useState<Category[]>([]);
  const [allProducts, setAllProducts] = useState<Product[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [categorySearchQuery, setCategorySearchQuery] = useState("");
  const [productSearchQuery, setProductSearchQuery] = useState("");
  const [productsLoading, setProductsLoading] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  useEffect(() => {
    const fetchDevice = async () => {
      try {
        if (!loading && axiosInstance) {
          const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_DEVICES ?? "";
          const response = await axiosInstance.get(`${apiUrl}${deviceId}/`);
          setDevice(response.data);
        }
      } catch (error) {
        console.error("Error fetching device:", error);
      }
    };

    const fetchAllCategories = async () => {
      try {
        setCategoriesLoading(true);
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
          let allFetchedCategories: Category[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedCategories = [...allFetchedCategories, ...results];
            nextUrl = next;
          }

          setAllCategories(allFetchedCategories);
          setFilteredCategories(allFetchedCategories);
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setCategoriesLoading(false);
      }
    };

    const fetchAllProducts = async () => {
      try {
        setProductsLoading(true);
        if (!loading && axiosInstance && user?.selectedBranch) {
          const apiUrl =
            process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
          let allFetchedProducts: Product[] = [];
          let nextUrl = `${apiUrl}?branch=${user.selectedBranch.id}`;

          while (nextUrl) {
            const response = await axiosInstance.get(nextUrl);
            const { results, next } = response.data;
            allFetchedProducts = [...allFetchedProducts, ...results];
            nextUrl = next;
          }

          setAllProducts(allFetchedProducts);
          setFilteredProducts(allFetchedProducts);
        }
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setProductsLoading(false);
      }
    };

    if (deviceId) {
      fetchDevice();
      fetchAllCategories();
      fetchAllProducts();
    }
  }, [axiosInstance, loading, deviceId, user?.selectedBranch]);

  const handleUpdateDevice = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      if (!loading && axiosInstance) {
        const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_DEVICES ?? "";
        const response = await axiosInstance.put(
          `${apiUrl}${deviceId}/`,
          device,
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );

        if (response.status === 200) {
          const successMessage = t(
            "back.management.business.device.successUpdate",
          );
          setIsSuccess(true);
          setBannerMessage(successMessage);
          setShowBanner(true);
          setSuccessMessage(successMessage);
          onMenuItemClick("devices", successMessage);
        } else {
          const errorMessage = t("back.management.business.device.failUpdate");
          setIsSuccess(false);
          setBannerMessage(errorMessage);
          setShowBanner(true);
        }
      }
    } catch (error) {
      const errorMessage = t("back.management.business.device.failUpdate");
      console.error("Error updating device", error);
      setIsSuccess(false);
      setBannerMessage(errorMessage);
      setShowBanner(true);
    }
  };

  const handleCategoryChange = (selectedCategoryId: number) => {
    setDevice((prevDevice) => {
      const categories = prevDevice.categories.includes(selectedCategoryId)
        ? prevDevice.categories.filter((id) => id !== selectedCategoryId)
        : [...prevDevice.categories, selectedCategoryId];

      return {
        ...prevDevice,
        categories,
      };
    });
  };

  const handleProductChange = (selectedProductId: number) => {
    setDevice((prevDevice) => {
      const products = prevDevice.products.includes(selectedProductId)
        ? prevDevice.products.filter((id) => id !== selectedProductId)
        : [...prevDevice.products, selectedProductId];

      return {
        ...prevDevice,
        products,
      };
    });
  };

  const handleRemoveCategory = (categoryId: number) => {
    setDevice((prevDevice) => ({
      ...prevDevice,
      categories: prevDevice.categories.filter((id) => id !== categoryId),
    }));
  };

  const handleRemoveProduct = (productId: number) => {
    setDevice((prevDevice) => ({
      ...prevDevice,
      products: prevDevice.products.filter((id) => id !== productId),
    }));
  };

  const debouncedCategorySearch = useCallback(
    _.debounce((query: string) => {
      setCategorySearchQuery(query);
      if (query === "") {
        setFilteredCategories(allCategories);
      } else {
        setFilteredCategories(
          allCategories.filter((category) =>
            category.name.toLowerCase().includes(query.toLowerCase()),
          ),
        );
      }
    }, 300),
    [allCategories],
  );

  const debouncedProductSearch = useCallback(
    _.debounce((query: string) => {
      setProductSearchQuery(query);
      if (query === "") {
        setFilteredProducts(allProducts);
      } else {
        setFilteredProducts(
          allProducts.filter((product) =>
            product.name.toLowerCase().includes(query.toLowerCase()),
          ),
        );
      }
    }, 300),
    [allProducts],
  );

  useEffect(() => {
    return () => {
      debouncedCategorySearch.cancel();
      debouncedProductSearch.cancel();
    };
  }, [debouncedCategorySearch, debouncedProductSearch]);

  const handleCategorySearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    debouncedCategorySearch(e.target.value);
  };

  const handleProductSearchChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    debouncedProductSearch(e.target.value);
  };

  useEffect(() => {
    if (showBanner) {
      setAnimateOut(false);
      const timerId = setTimeout(() => {
        setAnimateOut(true);
        setTimeout(() => setShowBanner(false), 500);
      }, 3000);

      return () => clearTimeout(timerId);
    }
  }, [showBanner]);

  // Memoize selected categories and products to avoid unnecessary re-renders
  const selectedCategories = useMemo(
    () =>
      allCategories.filter((category) =>
        device.categories.includes(category.id),
      ),
    [allCategories, device.categories],
  );

  const selectedProducts = useMemo(
    () => allProducts.filter((product) => device.products.includes(product.id)),
    [allProducts, device.products],
  );

  return (
    <PageLoader isFetching={isFetching}>
      <div className="container mx-auto px-4 py-2">
        {showBanner && (
          <WarningBanner
            title={isSuccess ? "Success" : "Error"}
            text={bannerMessage}
            isSuccess={isSuccess}
            className={`${
              animateOut ? "animate-slideOutRight" : "animate-slideDown"
            }`}
          />
        )}
        {device.id ? (
          <>
            <h1 className="text-xl font-bold">
              {t("back.management.business.device.editDevice")}
            </h1>
            <form className="mt-8 space-y-6" onSubmit={handleUpdateDevice}>
              <div>
                <label
                  htmlFor="name"
                  className="text-sm font-medium text-gray-700 block mb-2"
                >
                  {t("back.management.business.device.name")}
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  placeholder={t("back.management.business.device.enterName")}
                  value={device.name}
                  onChange={(e) =>
                    setDevice({ ...device, name: e.target.value })
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="device_number"
                  className="text-sm font-medium text-gray-700 block mb-2"
                >
                  {t("back.management.business.device.deviceNumber")}
                </label>
                <input
                  id="device_number"
                  name="device_number"
                  type="text"
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  placeholder={t(
                    "back.management.business.device.enterDeviceNumber",
                  )}
                  value={device.device_number}
                  onChange={(e) =>
                    setDevice({ ...device, device_number: e.target.value })
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="status"
                  className="text-sm font-medium text-gray-700 block mb-2"
                >
                  {t("back.management.business.device.status")}
                </label>
                <select
                  id="status"
                  name="status"
                  value={device.status}
                  onChange={(e) =>
                    setDevice({ ...device, status: e.target.value })
                  }
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                >
                  <option value="Active">
                    {t("back.management.business.device.active")}
                  </option>
                  <option value="Inactive">
                    {t("back.management.business.device.inactive")}
                  </option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="mode"
                  className="text-sm font-medium text-gray-700 block mb-2"
                >
                  {t("back.management.business.device.mode")}
                </label>
                <select
                  id="mode"
                  name="mode"
                  value={device.mode}
                  onChange={(e) =>
                    setDevice({ ...device, mode: e.target.value })
                  }
                  required
                  className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                >
                  <option value="Offline">
                    {t("back.management.business.device.offline")}
                  </option>
                  <option value="Online">
                    {t("back.management.business.device.online")}
                  </option>
                </select>
              </div>
              <div className="flex flex-wrap -mx-4">
                <div className="w-full md:w-1/2 px-4 mb-4">
                  <label
                    htmlFor="categories"
                    className="text-sm font-medium text-gray-700 block mb-2"
                  >
                    {t("back.management.business.device.categories")}
                  </label>
                  <input
                    type="text"
                    placeholder={t(
                      "back.management.business.device.searchCategories",
                    )}
                    onChange={handleCategorySearchChange}
                    className="mb-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                  {categoriesLoading ? (
                    <Spinner />
                  ) : (
                    <div className="p-4 border rounded-md max-h-40 overflow-y-auto">
                      <p className="text-gray-700 text-sm font-bold mb-2">
                        {t(
                          "back.management.business.device.selectedCategories",
                        )}
                      </p>
                      <ul>
                        {selectedCategories.map((category) => (
                          <li
                            key={category.id}
                            className="flex items-center mb-2"
                          >
                            <span className="text-gray-700 mr-2">
                              {category.name}
                            </span>
                            <button
                              onClick={() => handleRemoveCategory(category.id)}
                              className="text-red-500 hover:text-red-700 focus:outline-none"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <select
                    id="categories"
                    multiple
                    value={device.categories.map(String)}
                    onChange={(e) =>
                      handleCategoryChange(parseInt(e.target.value, 10))
                    }
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm mt-2"
                  >
                    {filteredCategories
                      .filter(
                        (category) => !device.categories.includes(category.id),
                      )
                      .map((category) => (
                        <option key={category.id} value={category.id}>
                          {category.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="w-full md:w-1/2 px-4 mb-4">
                  <label
                    htmlFor="products"
                    className="text-sm font-medium text-gray-700 block mb-2"
                  >
                    {t("back.management.business.device.products")}
                  </label>
                  <input
                    type="text"
                    placeholder={t(
                      "back.management.business.device.searchProducts",
                    )}
                    onChange={handleProductSearchChange}
                    className="mb-2 appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                  />
                  {productsLoading ? (
                    <Spinner />
                  ) : (
                    <div className="p-4 border rounded-md max-h-40 overflow-y-auto">
                      <p className="text-gray-700 text-sm font-bold mb-2">
                        {t("back.management.business.device.selectedProducts")}
                      </p>
                      <ul>
                        {selectedProducts.map((product) => (
                          <li
                            key={product.id}
                            className="flex items-center mb-2"
                          >
                            <span className="text-gray-700 mr-2">
                              {product.name}
                            </span>
                            <button
                              onClick={() => handleRemoveProduct(product.id)}
                              className="text-red-500 hover:text-red-700 focus:outline-none"
                            >
                              <FontAwesomeIcon icon={faTrashAlt} />
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                  <select
                    id="products"
                    multiple
                    value={device.products.map(String)}
                    onChange={(e) =>
                      handleProductChange(parseInt(e.target.value, 10))
                    }
                    className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm mt-2"
                  >
                    {filteredProducts
                      .filter(
                        (product) => !device.products.includes(product.id),
                      )
                      .map((product) => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div>
                <label
                  htmlFor="is_primary"
                  className="text-sm font-medium text-gray-700 block mb-2"
                >
                  {t("back.management.business.device.isPrimary")}
                </label>
                <input
                  id="is_primary"
                  name="is_primary"
                  type="checkbox"
                  className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
                  checked={device.is_primary}
                  onChange={(e) =>
                    setDevice({ ...device, is_primary: e.target.checked })
                  }
                />
              </div>
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  {t("back.management.business.device.updateDevice")}
                </button>
              </div>
            </form>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => onMenuItemClick("devices")}
                className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
              >
                {t("back.management.menu.product.cancelbutton")}
              </button>
            </div>
          </>
        ) : (
          <p>{t("back.management.business.device.noDeviceSelected")}</p>
        )}
      </div>
    </PageLoader>
  );
};

export default EditDevice;
