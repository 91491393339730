import React, { useEffect, useState } from "react";
import PageLoader from "@/components/back/Spinner";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import DateFilterButton from "@/components/back/DateFilterButton";
import MonthToDateSummary from "./MonthToDateSummary";
import SelectedDateSummary from "./SelectedDateSummary";
import {
  startOfMonth,
  endOfMonth,
  startOfDay,
  endOfDay,
  addDays,
  addHours,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc, format } from "date-fns-tz";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";
import { useTranslation } from "react-i18next";

const initialSummaryData = {
  total_subtotal_sales: 0,
  total_tips: 0,
  total_tax: 0,
  total_paid_orders: 0,
  total_refunded_orders: 0,
  total_service_fee_charge: 0,
};

const Report: React.FC = () => {
  const [summaryData, setSummaryData] = useState(initialSummaryData);
  const [monthToDateTotals, setMonthToDateTotals] = useState<{
    total_subtotal_sales: number;
    total_tips: number;
    total_tax: number;
    total_paid_orders: number;
    total_refunded_orders: number;
    total_service_fee_charge: number;
  } | null>(null);
  const [totalsLoading, setTotalsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [axiosInstance, isFetching] = useAxiosInstance();
  const [selectedBranch, setSelectedBranch] = useState<number | null>(null);
  const [lastFetchedStartDate, setLastFetchedStartDate] = useState<
    string | null
  >(null);
  const { t } = useTranslation();

  const timeZone = "America/Chicago";

  const isDST = (date: Date): boolean => {
    const januaryOffset = new Date(
      date.getFullYear(),
      0,
      1,
    ).getTimezoneOffset();
    const julyOffset = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
    return Math.max(januaryOffset, julyOffset) !== date.getTimezoneOffset();
  };

  const getUTCDateWithDSTAdjustment = (
    date: Date,
    isStartOfDay: boolean,
  ): Date => {
    const zonedDate = utcToZonedTime(date, timeZone);
    const utcOffset = isDST(zonedDate) ? 5 : 6;

    if (isStartOfDay) {
      return zonedTimeToUtc(
        addHours(startOfDay(zonedDate), utcOffset),
        timeZone,
      );
    } else {
      return zonedTimeToUtc(addHours(endOfDay(zonedDate), utcOffset), timeZone);
    }
  };

  const formatDateToUTC = (date: Date): string => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", { timeZone: "UTC" });
  };

  const getDateRange = (start: Date, end: Date) => {
    const startDate = getUTCDateWithDSTAdjustment(start, true);
    const endDate = getUTCDateWithDSTAdjustment(addDays(end, 1), true); // Use the start of the next day

    return {
      startDate: formatDateToUTC(startDate),
      endDate: formatDateToUTC(endDate),
    };
  };

  const getMonthToDateRange = () => {
    const now = new Date();
    const zonedNow = utcToZonedTime(now, timeZone);
    const firstDayOfMonth = startOfMonth(zonedNow);

    return getDateRange(firstDayOfMonth, zonedNow);
  };

  useEffect(() => {
    const storedData = localStorage.getItem("user_data");
    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData);
        if (parsedData.selectedBranch && parsedData.selectedBranch.id) {
          setSelectedBranch(parsedData.selectedBranch.id);
        } else {
          console.warn("No valid selected branch found in localStorage");
        }
      } catch (error) {
        console.error("Error parsing user data from localStorage:", error);
      }
    } else {
      console.warn("No user data found in localStorage");
    }
  }, []);

  const fetchSummaryData = async () => {
    try {
      setTotalsLoading(true);
      const { startDate: start, endDate: end } = getDateRange(
        startDate || new Date(),
        endDate || new Date(),
      );

      const url = `/api/summary/${selectedBranch}/?start_date=${start}&end_date=${end}`;

      const response = await axiosInstance.get(url);
      if (!response.status.toString().startsWith("2")) {
        throw new Error("Failed to fetch summary data");
      }

      setSummaryData(response.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setTotalsLoading(false);
    }
  };

  const fetchMonthToDateTotals = async () => {
    setError(null);

    try {
      const { startDate, endDate } = getMonthToDateRange();
      const url = `/api/summary/${selectedBranch}/?start_date=${startDate}&end_date=${endDate}`;

      const response = await axiosInstance.get(url);
      if (!response.status.toString().startsWith("2")) {
        throw new Error("Failed to fetch month-to-date totals");
      }

      setMonthToDateTotals(response.data);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (selectedBranch) {
        const { startDate } = getMonthToDateRange();
        if (startDate !== lastFetchedStartDate) {
          await fetchMonthToDateTotals();
          setLastFetchedStartDate(startDate);
        }
        await fetchSummaryData();
      }
      setLoading(false);
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000); // Check every minute

    return () => clearInterval(intervalId);
  }, [selectedBranch, startDate, endDate]);

  const handleDirectPrint = async () => {
    try {
      const { startDate: start, endDate: end } = getDateRange(
        startDate || new Date(),
        endDate || new Date(),
      );
      const requestData = {
        branch_id: selectedBranch,
        start_date: start,
        end_date: end,
      };

      const response = await axiosInstance.post(
        "/print/print-daily-report/",
        requestData,
      );

      if (response.status === 200) {
        console.log("Print job sent successfully");
      } else {
        throw new Error("Failed to send print job");
      }
    } catch (error) {
      console.error("Error sending print job:", error);
      setError("Failed to print report. Please try again.");
    }
  };

  return (
    <BranchRequiredWrapper>
      <PageLoader isFetching={isFetching}>
        <div className="min-h-screen p-4">
          <MonthToDateSummary
            monthToDateTotals={monthToDateTotals}
            loading={loading}
          />

          <div className="my-6 flex justify-center">
            <DateFilterButton
              onStartDateChange={(date) => setStartDate(date)}
              onEndDateChange={(date) => setEndDate(date)}
              startDate={startDate}
              endDate={endDate}
              labelText="Filter by date"
            />
            <button
              onClick={handleDirectPrint}
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
            >
              {t("back.management.report.print")}
            </button>
          </div>

          <SelectedDateSummary
            summaryData={summaryData}
            loading={totalsLoading}
          />
        </div>
      </PageLoader>
    </BranchRequiredWrapper>
  );
};

export default Report;
