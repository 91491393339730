import { StandaloneSearchBox } from "@react-google-maps/api";
import React, { useRef } from "react";
import toast from "react-hot-toast";
import { useAddressContext } from "../../contexts/AddressContext";

interface AddressAutocompleteProps {
  value: string;
  onChange: (value: string) => void;
  onPlaceSelected: (place: google.maps.places.PlaceResult) => void;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  value,
  onChange,
  onPlaceSelected,
}) => {
  const { isLoaded, loadError } = useAddressContext();
  const searchBoxRef = useRef<google.maps.places.SearchBox | null>(null);

  if (!isLoaded)
    return (
      <input
        type="text"
        placeholder="Loading address autocomplete..."
        disabled
      />
    );
  if (loadError) {
    toast.error(
      "Address autocomplete is not available. Please enter your address manually.",
    );
    return (
      <div>
        <label
          htmlFor="address"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Address
          <span className="text-red-600">*</span>
        </label>
        <div className="mt-2">
          <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Enter your address"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </div>
    );
  }

  const handlePlacesChanged = () => {
    const places = searchBoxRef.current?.getPlaces();
    if (places && places.length > 0) {
      onPlaceSelected(places[0]);
    }
  };

  return (
    <div>
      <label
        htmlFor="address"
        className="mt-2 block text-sm font-medium leading-6 text-gray-900"
      >
        Address
        <span className="text-red-600">*</span>
      </label>
      <StandaloneSearchBox
        onLoad={(ref) => (searchBoxRef.current = ref)}
        onPlacesChanged={handlePlacesChanged}
      >
        <div className="mt-2">
          <input
            type="text"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Enter your address"
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
      </StandaloneSearchBox>
    </div>
  );
};

export default AddressAutocomplete;
