import React, { CSSProperties, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import img1 from "@/assets/img/integrationsFeature1.png";
import img2 from "@/assets/img/integrationsFeature2.png";
import img3 from "@/assets/img/integrationsFeature3.png";
import img4 from "@/assets/img/integrationsFeature4.png";
import img5 from "@/assets/img/integrationsFeature5.png";
import img6 from "@/assets/img/integrationsFeature6.png";

import { useTranslation } from "react-i18next";

interface IntegrationFeatureProps {
  imgSrc: string;
  featureTitle: string;
  featureDescription: string;
  highlight: boolean;
}

const IntegrationFeature: React.FC<IntegrationFeatureProps> = ({
  imgSrc,
  featureTitle,
  featureDescription,
  highlight,
}) => {
  const isSmallScreen = window.innerWidth < 768;

  const featureStyle: React.CSSProperties = {
    margin: isSmallScreen ? "20px" : "0",
    maxWidth: isSmallScreen ? "80%" : "95%",
  };

  const imageStyle: React.CSSProperties = {
    maxWidth: isSmallScreen ? "150px" : "100%",
    height: "auto",
    objectFit: "cover",
    display: "block",
    margin: "0 auto",
  };

  return (
    <div
      style={featureStyle}
      className={`flex flex-col items-start text-left mb-6 ${
        highlight ? "bg-yellow-100" : ""
      }`}
    >
      <img src={imgSrc} alt={featureTitle} style={imageStyle} />
      <h3 className="text-red-500 text-xl font-bold">{featureTitle}</h3>
      <p className="text-gray-600">{featureDescription}</p>
    </div>
  );
};

const IntegrationsFeature: React.FC = () => {
  const { t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const styles: Record<string, CSSProperties> = {
    title: {
      fontSize: isMobile ? "2em" : "3em",
      fontWeight: "bold",
      color: "#000",
      marginBottom: "0.5em",
    },
    featureContainer: {
      display: isMobile ? "flex" : "grid",
      overflowX: isMobile ? "scroll" : "visible",
      gridTemplateColumns: isMobile ? "none" : "repeat(3, 1fr)",
      padding: isMobile ? "0 10px" : "0",
      width: isMobile ? "auto" : "100%",
      whiteSpace: isMobile ? "nowrap" : "normal",
    },
  };

  const [searchTerm, setSearchTerm] = useState("");

  const images = [img1, img2, img3, img4, img5, img6];
  const titles = images.map((_, index) =>
    t(`front.features.integrations.feature${index + 1}.title`),
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const highlightFeature = (title: string): boolean => {
    return (
      searchTerm !== "" &&
      title.toLowerCase().includes(searchTerm.toLowerCase())
    );
  };

  return (
    <div className="py-8 bg-white">
      <div className="text-center">
        <h2 style={styles.title}>{t("front.features.integrations.title2")}</h2>
        <div className="inline-block w-full md:w-3/4 lg:w-1/2 px-3 mb-6">
          <div className="relative rounded-full shadow-sm mt-10">
            <input
              className="pl-10 pr-3 py-2 w-full rounded-full border border-gray-300 focus:border-red-500 focus:ring focus:ring-red-200 focus:ring-opacity-50"
              type="text"
              placeholder={t("front.features.integrations.searchPlaceholder")}
              onChange={handleSearchChange}
            />
            <div className="absolute left-0 top-0 ml-4 mt-2.5">
              <FontAwesomeIcon icon={faSearch} className="text-gray-500" />
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div style={styles.featureContainer}>
          {images.map((imgSrc, index) => (
            <IntegrationFeature
              key={index}
              imgSrc={imgSrc}
              featureTitle={titles[index]}
              featureDescription={t(
                `front.features.integrations.feature${index + 1}.description`,
              )}
              highlight={highlightFeature(titles[index])}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default IntegrationsFeature;
