import React from "react";
import OrderTitle from "@/views/back/management/components/order/OrderTitle";
import OrderTable from "@/views/back/management/components/order/OrderTable";
import BranchRequiredWrapper from "@/components/ui/banners/BranchRequiredWrapper";

const Orders: React.FC = () => {
  return (
    <div className="container mx-auto px-4 py-2">
      <BranchRequiredWrapper>
        <OrderTitle />
        <OrderTable />
      </BranchRequiredWrapper>
    </div>
  );
};

export default Orders;
