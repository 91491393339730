import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faImage } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useTranslation } from "react-i18next";

interface AddProductProps {
  onMenuItemClick: (componentName: string) => void;
}

const AddCustomer = ({ onMenuItemClick }: AddProductProps) => {
  const { t } = useTranslation();

  const [itemName, setItemName] = useState("");
  const [posMenuColor, setPosMenuColor] = useState("");
  const [description, setDescription] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [promotion, setPromotion] = useState("");
  const [stock, setStock] = useState("");
  const [categories, setCategories] = useState([""]);
  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [imagePreviewUrl, setImagePreviewUrl] = useState("");
  const [imageBase64, setImageBase64] = useState<string>("");

  //encoding 64 base image
  const fileToBase64 = (
    file: File,
    callback: (result: string | ArrayBuffer | null) => void,
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => callback(reader.result);
    reader.onerror = (error) =>
      console.log("Error converting file to Base64:", error);
  };

  //color options
  const colorOptions = [
    { label: "Red", value: "#ff0000" },
    { label: "Green", value: "#00ff00" },
    { label: "Blue", value: "#0000ff" },
    { label: "Yellow", value: "#ffff00" },
    { label: "Purple", value: "#800080" },
    { label: "Orange", value: "#ffa500" },
    { label: "Black", value: "#000000" },
  ];

  const handleColorChange = (value: string) => {
    const selectedColor = colorOptions.find((option) => option.value === value);
    setPosMenuColor(selectedColor ? selectedColor.label : "");
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setImagePreviewUrl(URL.createObjectURL(file));
      setIsImageUploaded(true);

      // Convert file to Base64
      fileToBase64(file, (base64Result: string | ArrayBuffer | null) => {
        // Ensure base64Result is a string before setting it
        if (typeof base64Result === "string") {
          setImageBase64(base64Result); // Store Base64 string
        }
      });
    }
  };

  const handleAddCustomer = async () => {
    //get local time
    const addedDate = new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    const productData = {
      itemName,
      posMenuColor,
      description,
      price: parseFloat(basePrice),
      promotion,
      stock: parseInt(stock, 10),
      categories,
      photo: imageBase64,
      addedDate,
    };

    try {
      const response = await axios.post(
        "http://localhost:3001/menu",
        productData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      console.log("Product added successfully:", response.data);
      onMenuItemClick("product");
    } catch (error) {
      console.error("Error adding product:", error);
    }
  };

  const handleAddCategoryField = () => {
    setCategories([...categories, ""]);
  };

  const handleCategoryChange = (index: number, value: string) => {
    const updatedCategories = categories.map((category, i) =>
      i === index ? value : category,
    );
    setCategories(updatedCategories);
  };

  const handleRemoveCategoryField = (index: number) => {
    if (categories.length > 1) {
      const updatedCategories = categories.filter((_, i) => i !== index);
      setCategories(updatedCategories);
    }
  };

  const handleRemoveImage = () => {
    setImagePreviewUrl("");
    setIsImageUploaded(false);
  };

  // Placeholder for promotions and categories options
  const promotionsOptions = [
    <option key="promo1" value="promo1">
      Promo 1
    </option>,
  ];

  return (
    <div className="container mx-auto px-4 py-2">
      <h1 className="text-xl font-bold">
        {t("back.management.customer.addnewcustomer")}
      </h1>

      {/* General Information Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold">
          {t("back.management.customer.generalinfo")}
        </h2>
        <div className="flex flex-wrap -mx-2">
          <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="productName"
            >
              {t("back.management.customer.firstname")}
            </label>
            <input
              type="text"
              id="itemName"
              placeholder="Enter Customer First Name"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
            />
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="productName"
            >
              {t("back.management.customer.lastname")}
            </label>
            <input
              type="text"
              id="itemName"
              placeholder="Enter Customer Phone Number"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
            />
          </div>
        </div>
        <div className="flex flex-wrap -mx-2 mt-5">
          <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="productName"
            >
              {t("back.management.customer.phone")}
            </label>
            <input
              type="text"
              id="itemName"
              placeholder="Enter Customer FEmail Address"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
            />
          </div>
          <div className="w-full md:w-1/2 px-2 mb-4 md:mb-0">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="productName"
            >
              {t("back.management.customer.email")}
            </label>
            <input
              type="text"
              id="itemName"
              placeholder="Enter Customer Last Name"
              value={itemName}
              onChange={(e) => setItemName(e.target.value)}
              className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
            />
          </div>
        </div>
        <div className="mt-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="description"
          >
            {t("back.management.customer.specialnotes")}
          </label>
          <textarea
            id="description"
            placeholder="Type notes here..."
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring h-48"
          ></textarea>
        </div>
      </div>

      {/* Media Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold">
          {t("back.management.menu.product.media")}
        </h2>
        <p className="block text-gray-700 text-sm font-bold mt-4 mb-2">
          {t("back.management.menu.product.uploadimage")}
        </p>
        <div
          className={`block w-full border-2 ${
            isImageUploaded ? "border-solid" : "border-dotted"
          } border-gray-300 rounded-md shadow-sm flex justify-center items-center relative cursor-pointer hover:border-gray-500 h-48`}
        >
          <input
            id="fileUpload"
            type="file"
            className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
            onChange={handleFileChange}
          />
          {imagePreviewUrl ? (
            <>
              <img
                src={imagePreviewUrl}
                alt="Preview"
                className="max-h-full max-w-full p-2"
              />
              <button
                onClick={handleRemoveImage}
                className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full m-2"
                style={{ width: "30px", height: "30px" }}
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            </>
          ) : (
            <div className="text-center">
              <FontAwesomeIcon
                icon={faImage}
                size="2x"
                className="text-gray-400 mb-2"
              />
              <p className="text-gray-600 mb-2">
                {t("back.management.menu.product.uploadimagehint")}
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Pricing Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold">
          {t("back.management.menu.product.pricing")}
        </h2>
        <input
          type="text"
          placeholder="Base Price"
          value={basePrice}
          onChange={(e) => setBasePrice(e.target.value)}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
        />
        <select
          value={promotion}
          onChange={(e) => setPromotion(e.target.value)}
          className="block w-full mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
        >
          <option value="">
            {t("back.management.menu.product.selectpromotion")}
          </option>
          {promotionsOptions}
        </select>
      </div>

      {/* Inventory Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold">
          {t("back.management.menu.product.inventory")}
        </h2>
        <input
          type="text"
          placeholder="Stock"
          value={stock}
          onChange={(e) => setStock(e.target.value)}
          className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring"
        />
      </div>

      {/* Category Section */}
      <div className="my-4 bg-white p-4 shadow rounded-lg">
        <h2 className="text-lg font-semibold">
          {t("back.management.menu.product.category")}
        </h2>
        {categories.map((category, index) => (
          <div key={index} className="flex items-center">
            <select
              value={category}
              onChange={(e) => handleCategoryChange(index, e.target.value)}
              className="block flex-1 mt-2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring mb-2"
            >
              <option value="">
                {t("back.management.menu.product. selectcategory")}
              </option>
              {/* Dynamically generate category options here */}
            </select>
            {categories.length > 1 && (
              <button
                onClick={() => handleRemoveCategoryField(index)}
                className="ml-2 text-red-500 hover:text-red-700"
              >
                <FontAwesomeIcon icon={faTrashAlt} />
              </button>
            )}
          </div>
        ))}
        <button
          onClick={handleAddCategoryField}
          className="px-4 py-2 bg-blue-500 text-white rounded-md mt-2"
        >
          {t("back.management.menu.product.addmorebutton")}
        </button>
      </div>

      {/* Action Buttons */}
      <div className="flex justify-end mt-4">
        <button
          onClick={() => onMenuItemClick("product")}
          className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
        >
          {t("back.management.menu.product.cancelbutton")}
        </button>
        <button
          onClick={handleAddCustomer}
          className="px-4 py-2 bg-blue-500 text-white rounded-md"
        >
          {t("back.management.menu.product.confirmbutton")}
        </button>
      </div>
    </div>
  );
};

export default AddCustomer;
