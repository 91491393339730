import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAddressContext } from "../../contexts/AddressContext";

export interface Address {
  street: string;
  city: string;
  state: string;
  country: string;
  pincode: string;
}

export const useAddressAutocomplete = (initialAddress: Address) => {
  const { isLoaded, loadError } = useAddressContext();
  const [address, setAddress] = useState<Address>(initialAddress);

  useEffect(() => {
    if (loadError) {
      toast.error(
        "Failed to load address autocomplete. Please enter address manually.",
      );
    }
  }, [loadError]);

  const handlePlaceSelected = useCallback(
    (place: google.maps.places.PlaceResult) => {
      let newAddress: Address = {
        street: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
      };

      place.address_components?.forEach((component) => {
        const componentType = component.types[0];
        switch (componentType) {
          case "street_number":
            newAddress.street = component.long_name + " " + newAddress.street;
            break;
          case "route":
            newAddress.street += component.long_name;
            break;
          case "locality":
            newAddress.city = component.long_name;
            break;
          case "administrative_area_level_1":
            newAddress.state = component.long_name;
            break;
          case "country":
            newAddress.country = component.long_name;
            break;
          case "postal_code":
            newAddress.pincode = component.long_name;
            break;
        }
      });
      setAddress(newAddress);
      toast.success("Address selected successfully!");
    },
    [],
  );

  const handleInputChange = useCallback(
    (field: keyof Address, value: string) => {
      setAddress((prev) => ({ ...prev, [field]: value }));
    },
    [],
  );

  return {
    address,
    setAddress,
    handlePlaceSelected,
    handleInputChange,
    isLoaded,
    loadError,
  };
};
