import React, { useState } from "react";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faCopy,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import toast from "react-hot-toast";

Modal.setAppElement("#root");

interface SetupPrinterProps {
  onMenuItemClick: (menuItem: string) => void;
}
const SetupPrinter: React.FC<SetupPrinterProps> = ({ onMenuItemClick }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [credentialsModalIsOpen, setCredentialsModalIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const { user } = useUser();
  const [axiosInstance, loading, isFetching] = useAxiosInstance();
  const [message, setMessage] = useState("");
  const [credentials, setCredentials] = useState<any>(null);
  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const openCredentialsModal = () => setCredentialsModalIsOpen(true);
  const closeCredentialsModal = () => {
    setCredentialsModalIsOpen(false);
    setCredentials(null);
  };

  const handleNextStep = () => {
    setStep(2);
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Copied to clipboard!");
    });
  };

  const handleDownload = (platform: string) => {
    const url =
      platform === "windows"
        ? `${process.env.REACT_APP_API_BASE_URL}print/download/windows/`
        : `${process.env.REACT_APP_API_BASE_URL}print/download/osx/`;
    window.location.href = url;
    setStep(2);
    closeModal();
  };

  const handleSignUp = async () => {
    try {
      if (!axiosInstance || !user?.selectedBranch) {
        console.error("Axios instance or selected branch not available");
        return;
      }
      const response = await axiosInstance.post("print/childaccount/", {
        branch_id: user.selectedBranch.id,
      });
      setMessage("Successfully signed up for Redbird Print Account.");
      setStep(3);
    } catch (error: any) {
      if (error.response && error.response.status === 409) {
        setMessage(
          "This branch already has a Redbird Print Account. Please see your credentials in the next section.",
        );
        setStep(3);
      } else {
        console.error("Error signing up:", error);
        setMessage("An error occurred while signing up. Please try again.");
      }
    }
  };

  const handleRevealCredentials = async () => {
    try {
      if (!axiosInstance || !user?.selectedBranch) {
        console.error("Axios instance or selected branch not available");
        return;
      }

      const response = await axiosInstance.get(
        `print/childaccount/?branch_id=${user.selectedBranch.id}`,
      );
      if (response.status === 200) {
        setCredentials(response.data);
        setCredentialsModalIsOpen(true);
        setStep(4);
      }
    } catch (error) {
      console.error("Error fetching credentials:", error);
      setMessage(
        "An error occurred while fetching credentials. Please try again.",
      );
    }
  };

  const customModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0",
      border: "none",
      borderRadius: "0.5rem",
      maxWidth: "90vw",
      width: "800px",
      maxHeight: "90vh",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)",
    },
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <button
          onClick={() => onMenuItemClick("printer")}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
        >
          <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
          Back to Printers
        </button>
        <h1 className="text-2xl font-bold">Printer Setup</h1>
      </div>
      <div className="mt-4 space-y-4">
        {step >= 1 && (
          <div className="card p-4 shadow-md border rounded-md">
            <h2 className="text-xl font-semibold mb-4">
              Step 1: Download Print Client
            </h2>
            <div className="flex flex-col items-center">
              <button
                onClick={openModal}
                className="mb-4 w-full md:w-64 px-4 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                {t("back.management.printer.downloadClient")}
              </button>
              <h3 className="text-lg font-semibold mt-4 mb-2">
                Already have Redbird Print Client?
              </h3>
              <button
                onClick={handleNextStep}
                className="w-full md:w-64 px-4 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Next Step
              </button>
            </div>
          </div>
        )}
        {step >= 2 && (
          <div className="card p-4 shadow-md border rounded-md">
            <h2 className="text-xl font-semibold mb-4">
              Step 2: Sign Up for Redbird Print Account
            </h2>
            <div className="flex justify-center">
              <button
                onClick={handleSignUp}
                className="mb-2 w-full md:w-64 px-4 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Sign Up
              </button>
            </div>
            {message && <p className="mt-2 text-sm text-gray-600">{message}</p>}
          </div>
        )}
        {step >= 3 && (
          <div className="card p-4 shadow-md border rounded-md">
            <h2 className="text-xl font-semibold mb-4">
              Step 3: Reveal Your Credentials
            </h2>
            <div className="flex justify-center">
              <button
                onClick={handleRevealCredentials}
                className="mb-2 w-full md:w-64 px-4 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
              >
                Reveal Credentials
              </button>
            </div>
          </div>
        )}
        {step >= 4 && (
          <div className="card p-4 shadow-md border rounded-md">
            <h2 className="text-xl font-semibold mb-4">
              Step 4: Double Check Previous Steps
            </h2>
            <p>Ensure you have completed all previous steps correctly.</p>
          </div>
        )}
      </div>

      {/* Download Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Download Print Client"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <h2 className="text-xl font-semibold mb-4">Download Print Client</h2>
        <div className="flex flex-col items-center">
          <div className="w-full max-w-xs">
            <button
              onClick={() => handleDownload("windows")}
              className="mb-2 w-full px-4 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              Download for Windows
            </button>
            <button
              onClick={() => handleDownload("osx")}
              className="mb-4 w-full px-4 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              Download for macOS
            </button>
          </div>
          <button
            onClick={closeModal}
            className="px-4 py-2 bg-gray-200 rounded-md font-bold shadow hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          >
            <FontAwesomeIcon icon={faTimes} className="mr-2" />
            Close
          </button>
        </div>
      </Modal>

      {/* Credentials Modal */}
      <Modal
        isOpen={credentialsModalIsOpen}
        onRequestClose={closeCredentialsModal}
        contentLabel="Your Credentials"
        style={customModalStyles}
      >
        <div className="p-6 max-h-[calc(90vh-2rem)] overflow-y-auto">
          <h2 className="text-2xl font-bold mb-6 text-gray-800">
            Your Credentials
          </h2>
          <div className="flex flex-col items-center">
            {credentials && (
              <div className="mb-6 w-full">
                <div className="bg-white shadow-md rounded-lg overflow-hidden">
                  <div className="px-6 py-4 bg-gray-50 border-b border-gray-200">
                    <h3 className="text-lg font-semibold text-gray-700">
                      Account Information
                    </h3>
                  </div>
                  <div className="px-6 py-4 space-y-4">
                    {Object.entries(credentials).map(([key, value]) => (
                      <div
                        key={key}
                        className="flex items-center justify-between"
                      >
                        <span className="text-sm font-medium text-gray-600 capitalize">
                          {key.replace(/_/g, " ")}:
                        </span>
                        <div className="flex items-center">
                          <span className="text-sm text-gray-800 font-mono bg-gray-100 px-2 py-1 rounded">
                            {String(value)}
                          </span>
                          <button
                            onClick={() => copyToClipboard(String(value))}
                            className="ml-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                            title="Copy to clipboard"
                          >
                            <FontAwesomeIcon icon={faCopy} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <button
              onClick={closeCredentialsModal}
              className="px-6 py-2 bg-red-500 text-white rounded-md font-bold shadow hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
            >
              <FontAwesomeIcon icon={faTimes} className="mr-2" />
              Close
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SetupPrinter;
