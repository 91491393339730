import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useUser } from "@/contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Card, CardHeader, CardContent } from "@/components/back/Card";

interface Category {
  id: number;
  name: string;
}

interface Product {
  id: number;
  name: string;
  menu_display_name: string;
}

interface ProductSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (selectedProducts: Product[]) => void;
  initialSelectedProducts: number[];
}

const ProductSelectionModal: React.FC<ProductSelectionModalProps> = ({
  isOpen,
  onClose,
  onSave,
  initialSelectedProducts,
}) => {
  const { t } = useTranslation();
  const [axiosInstance, loading] = useAxiosInstance();
  const { user } = useUser();
  const [categories, setCategories] = useState<Category[]>([]);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null,
  );
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedProducts, setSelectedProducts] = useState<number[]>(
    initialSelectedProducts,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showProducts, setShowProducts] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const itemsPerPage = 20;

  const observer = useRef<IntersectionObserver | null>(null);
  const lastItemRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isFetching) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [isFetching, hasMore],
  );

  useEffect(() => {
    if (isOpen) {
      setCategories([]);
      setProducts([]);
      setShowProducts(false);
      setSelectedCategory(null);
      setSearchQuery("");
      setCurrentPage(1);
      setHasMore(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && !showProducts) {
      fetchCategories();
    }
  }, [isOpen, currentPage, showProducts]);

  useEffect(() => {
    if (showProducts || searchQuery) {
      fetchProducts(selectedCategory?.name || null, searchQuery);
    }
  }, [currentPage, showProducts, searchQuery, selectedCategory]);

  const fetchCategories = async () => {
    if (loading || !axiosInstance || !user?.selectedBranch || isFetching)
      return;
    setIsFetching(true);
    try {
      const apiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_CATEGORY_INFORMATION ?? "";
      let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;

      const response = await axiosInstance.get(url);
      setCategories((prev) => [...prev, ...response.data.results]);
      setHasMore(response.data.next !== null);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const fetchProducts = async (
    categoryName: string | null,
    query: string = "",
  ) => {
    if (loading || !axiosInstance || !user?.selectedBranch || isFetching)
      return;
    setIsFetching(true);
    try {
      const apiUrl =
        process.env.REACT_APP_REDBIRDPOSBE_PRODUCT_INFORMATION ?? "";
      let url = `${apiUrl}?branch=${user.selectedBranch.id}&page=${currentPage}&page_size=${itemsPerPage}`;

      if (categoryName) {
        url += `&category=${encodeURIComponent(categoryName)}`;
      }
      if (query) {
        url += `&menu_display_name=${encodeURIComponent(query)}`;
      }

      const response = await axiosInstance.get(url);
      setProducts((prev) =>
        currentPage === 1
          ? response.data.results
          : [...prev, ...response.data.results],
      );
      setHasMore(response.data.next !== null);
      setShowProducts(true);
    } catch (error) {
      console.error("Error fetching products:", error);
    } finally {
      setIsFetching(false);
    }
  };

  const handleCategoryClick = (category: Category) => {
    setSelectedCategory(category);
    setSearchQuery("");
    setCurrentPage(1);
    setProducts([]);
    fetchProducts(category.name);
  };

  const handleSearch = () => {
    setCurrentPage(1);
    setSelectedCategory(null);
    setProducts([]);
    fetchProducts(null, searchQuery);
  };

  const handleProductToggle = (productId: number) => {
    setSelectedProducts((prev) =>
      prev.includes(productId)
        ? prev.filter((id) => id !== productId)
        : [...prev, productId],
    );
  };

  const handleSave = () => {
    const selectedProductObjects = products.filter((product) =>
      selectedProducts.includes(product.id),
    );
    onSave(selectedProductObjects);
    onClose();
  };

  const handleBackToCategories = () => {
    setShowProducts(false);
    setSelectedCategory(null);
    setSearchQuery("");
    setCurrentPage(1);
    setCategories([]);
    fetchCategories();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <Card className="w-full max-w-lg h-[80vh] flex flex-col">
        <CardHeader className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">{t("Select Products")}</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            &times;
          </button>
        </CardHeader>
        <div className="px-6 py-4">
          <div className="mb-4 flex">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder={t("Search Product...")}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-l-md"
            />
            <button
              onClick={handleSearch}
              className="px-3 py-2 bg-gray-500 text-white rounded-r-md"
            >
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <CardContent className="flex-grow overflow-y-auto">
          {!showProducts ? (
            <div>
              {categories.map((category, index) => (
                <div
                  key={category.id}
                  ref={index === categories.length - 1 ? lastItemRef : null}
                  onClick={() => handleCategoryClick(category)}
                  className="flex justify-between items-center p-2 hover:bg-gray-100 cursor-pointer"
                >
                  <span>{category.name}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                </div>
              ))}
            </div>
          ) : (
            <div>
              <button
                onClick={handleBackToCategories}
                className="mb-2 text-blue-500 hover:underline"
              >
                {t("Back to Categories")}
              </button>
              {products.map((product, index) => (
                <div
                  key={product.id}
                  ref={index === products.length - 1 ? lastItemRef : null}
                  className="flex items-center p-2"
                >
                  <input
                    type="checkbox"
                    id={`product-${product.id}`}
                    checked={selectedProducts.includes(product.id)}
                    onChange={() => handleProductToggle(product.id)}
                    className="mr-2"
                  />
                  <label htmlFor={`product-${product.id}`}>
                    {product.menu_display_name}
                  </label>
                </div>
              ))}
            </div>
          )}
          {isFetching && <div className="text-center py-2">Loading...</div>}
        </CardContent>
        <div className="flex justify-end p-4 border-t">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
          >
            {t("Cancel")}
          </button>
          <button
            onClick={handleSave}
            className="px-4 py-2 bg-red-500 text-white rounded-md"
          >
            {t("Confirm")}
          </button>
        </div>
      </Card>
    </div>
  );
};

export default ProductSelectionModal;
