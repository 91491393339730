import React, { useEffect, useState } from "react";
import Spinner from "@/components/front/Loader/Spinner";
import OnlineOrder from "@/components/front/OnlineOrder";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation, Navigate } from "react-router-dom";

const API_URL = process.env.REACT_APP_API_BASE_URL;

interface Address {
  id: number;
  street: string;
  city: string;
  province: string;
  province_code: string;
  postal_code: string;
  country: string;
  country_code: string;
  latitude: number;
  longitude: number;
}

interface Branch {
  id: number;
  name: string;
  operating_hours: OperatingHour[];
  service_fee: string;
  tax_rate: string;
  logo: string;
  promotion_text: string;
  address: Address;
  phone: string;
  website: string;
}

interface OperatingHour {
  id: number;
  day: string;
  open_time: string;
  close_time: string;
}

const SubdomainComponent: React.FC<{
  subdomain: string;
  hostname: string;
  businessName: string;
}> = ({ subdomain, hostname, businessName }) => {
  const [branches, setBranches] = useState<{
    count: number;
    results: Branch[];
  }>({ count: 0, results: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    const fetchInitialData = async () => {
      localStorage.setItem("subdomain", subdomain);
      setLoading(true);
      try {
        const response = await fetch(
          `${API_URL}api/v1/branch/?business_name=${businessName}`,
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        setBranches(result);
        console.log("Fetched branches:", result);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
        setIsInitialLoad(false);
      }
    };

    fetchInitialData();
  }, [subdomain, businessName]);

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Online Order</title>
        </Helmet>
      </HelmetProvider>
      <div className="relative overflow-hidden bg-white dark:bg-gray-900 text-gray-800 dark:text-slate-200">
        {loading ? (
          <Spinner />
        ) : error ? (
          <p>Error: {error}</p>
        ) : (
          <OnlineOrder
            subdomain={subdomain}
            hostname={hostname}
            branches={branches}
            isInitialLoad={isInitialLoad}
          />
        )}
      </div>
    </div>
  );
};

const TestStore: React.FC = () => {
  const hostname = window.location.hostname;
  const location = useLocation();

  // Function to extract subdomain
  const getSubdomain = (hostname: string): string | null => {
    const parts = hostname.split(".");
    if (parts.length > 2) {
      return parts[0];
    }
    return null;
  };

  // Handle dev.redbirdtab.com, sandbox.redbirdtab.com, herokuapp.com domains, or localhost/store
  if (
    hostname === "dev.redbirdtab.com" ||
    hostname === "sandbox.redbirdtab.com" ||
    (hostname === "localhost" && location.pathname === "/store") ||
    (hostname === "192.168.1.144" && location.pathname === "/store")
  ) {
    return (
      <SubdomainComponent
        subdomain="jwtest"
        hostname={
          hostname === "localhost" || "192.168.1.144"
            ? "dev.redbirdtab.com"
            : hostname
        }
        businessName="jwtest"
      />
      //Sample Deploy
    );
  }

  const subdomain = getSubdomain(hostname);
  if (subdomain && hostname.endsWith("redbirdtab.com") && subdomain !== "www") {
    return (
      <SubdomainComponent
        subdomain={subdomain}
        hostname={hostname}
        businessName={subdomain}
      />
    );
  }

  if (hostname === "redbirdtab.com" && location.pathname === "/store") {
    return <Navigate to="/" replace />;
  }

  return <Navigate to="/" replace />;
};

export default TestStore;
