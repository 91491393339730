import React, { useEffect, useState } from "react";

const DownloadApp = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt,
      );
    };
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }
        setDeferredPrompt(null);
      });
    }
  };

  return (
    <div className="flex flex-col items-center mt-12 px-4 sm:px-0">
      <img src="../logo192.png" alt="App Logo" className="w-20 h-20 mb-4" />
      <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4 text-center">
        Download Our App
      </h1>
      <p className="text-base sm:text-lg text-gray-600 mb-6 text-center">
        Install our app for a better experience.
      </p>
      {deferredPrompt ? (
        <button
          className="bg-red-500 text-white font-bold py-3 px-6 rounded-full hover:bg-red-600 transition duration-300"
          onClick={handleInstallClick}
        >
          Install App
        </button>
      ) : (
        <p className="text-gray-600">
          Please click on the share button then click on "Add to Home Screen"!
        </p>
      )}
    </div>
  );
};

export default DownloadApp;
