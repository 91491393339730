import React from "react";
import { useTranslation } from "react-i18next";

interface MonthToDateTotals {
  total_subtotal_sales: number;
  total_tips: number;
  total_tax: number;
  total_paid_orders: number;
  total_refunded_orders: number;
  total_service_fee_charge: number;
}

interface Props {
  monthToDateTotals: MonthToDateTotals | null;
  loading: boolean;
}

const MonthToDateSummary: React.FC<Props> = ({
  monthToDateTotals,
  loading,
}) => {
  const { t } = useTranslation();
  return (
    <div className="mt-8 bg-white p-4 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">
        {t("back.management.report.monthToDate")}
      </h2>
      {loading ? (
        <div className="flex justify-center items-center h-32">
          <div className="spinner" />
        </div>
      ) : (
        <div className="grid grid-cols-2 gap-3 mt-4">
          <div>
            <p className="text-lg font-semibold mt-2">
              {t("back.management.report.subtotal")}
            </p>
            <p className="text-lg font-bold">
              $
              {monthToDateTotals?.total_subtotal_sales
                ? parseFloat(monthToDateTotals.total_subtotal_sales.toFixed(2))
                : "0.00"}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">
              {t("back.management.report.tip")}
            </p>
            <p className="text-lg font-bold">
              $
              {monthToDateTotals?.total_tips
                ? parseFloat(monthToDateTotals.total_tips.toFixed(2))
                : "0.00"}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">
              {t("back.management.report.tax")}
            </p>
            <p className="text-lg font-bold">
              $
              {monthToDateTotals?.total_tax
                ? parseFloat(monthToDateTotals.total_tax.toFixed(2))
                : "0.00"}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">
              {t("back.management.report.paidOrder")}
            </p>
            <p className="text-lg font-bold">
              {monthToDateTotals?.total_paid_orders
                ? monthToDateTotals.total_paid_orders
                : "0"}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">
              {t("back.management.report.failedOrder")}
            </p>
            <p className="text-lg font-bold">
              {monthToDateTotals?.total_refunded_orders
                ? monthToDateTotals.total_refunded_orders
                : "0"}
            </p>
          </div>
          <div>
            <p className="text-lg font-semibold mt-2">
              {t("back.management.report.serviceFee")}
            </p>
            <p className="text-lg font-bold">
              $
              {monthToDateTotals?.total_service_fee_charge
                ? parseFloat(
                    monthToDateTotals.total_service_fee_charge.toFixed(2),
                  )
                : "0.00"}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthToDateSummary;
