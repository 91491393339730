import { useUser } from "@/contexts/UserContext";
import { useAxiosInstance } from "@/utils/useAxiosInstance";
import { useEffect, useState } from "react";
import { AreaChart } from "@tinybirdco/charts";

const InfoCard = ({
  title,
  info,
}: {
  title: string;
  info: number | string;
}) => {
  return (
    <div key={title} className="bg-white p-6 rounded-lg">
      <h3 className="text-gray-400 mb-2">{title}</h3>
      <p className="text-3xl font-bold mb-2">{info}</p>
    </div>
  );
};

const colorPalette = [
  "#27F795",
  "#008060",
  "#0EB1B9",
  "#9263AF",
  "#5A6FC0",
  "#86BFDB",
  "#FFC145",
  "#FF6B6C",
  "#DC82C8",
  "#FFC0F1",
];

const Analytics = () => {
  const { user } = useUser();
  const [trend, setTrend] = useState("daily");
  const [datePicker, setDatePicker] = useState("24 Hour");
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [axiosInstance] = useAxiosInstance();
  const [form, setForm] = useState({
    sales: 0,
    average_order_value: 0,
    orders: 0,
    failed_orders: 0,
    retention_rate: 0,
    service_fees: 0,
    sales_trend: {} as any,
    top_products: [] as any[],
    sales_by_category: [] as any[],
  });

  const handleDateRange = (period: string) => {
    setDatePicker(period);
    const today = new Date();
    switch (period) {
      case "All Data":
        setStartDate(null);
        setEndDate(null);
        break;
      case "12 Months":
        setStartDate(new Date(today.setFullYear(today.getFullYear() - 1)));
        setEndDate(new Date());
        break;
      case "30 Days":
        setStartDate(new Date(today.setDate(today.getDate() - 30)));
        setEndDate(new Date());
        break;
      case "7 Days":
        setStartDate(new Date(today.setDate(today.getDate() - 7)));
        setEndDate(new Date());
        break;
      case "24 Hour":
        setStartDate(new Date(today.setDate(today.getDate() - 1)));
        setEndDate(new Date());
        break;
    }
  };

  const formatDate = (date: Date) => date.toISOString().split("T")[0];
  useEffect(() => {
    (async () => {
      if (!axiosInstance || !startDate || !endDate) return;
      const start = formatDate(startDate);
      const end = formatDate(endDate);
      try {
        const kpiRes = await axiosInstance.get(
          `/tinybird/get-events?pipe_name=kpi_dashboard&branch_id=${user?.selectedBranch?.id}&status=Preparing&date_from=${start}&date_end=${end}&time_period=${trend}`,
        );
        setForm((prev) => ({
          ...prev,
          average_order_value:
            kpiRes.data.data[0].average_order_value === null
              ? 0
              : kpiRes.data.data[0].average_order_value,
          sales: kpiRes.data.data[0].total_revenue,
          orders: kpiRes.data.data[0].total_orders,
          failed_orders: kpiRes.data.data[0].total_orders,
          retention_rate:
            kpiRes.data.data[0].retention_rate === null
              ? 0
              : kpiRes.data.data[0].retention_rate,
          service_fees: kpiRes.data.data[0].total_service_fees,
          sales_trend:
            kpiRes.data.data[0].time_series_data.length > 0
              ? {
                  data: kpiRes.data.data[0].time_series_data.map(
                    (item: any) => ({
                      date: item[0],
                      revenue: item[1],
                      orders: item[2],
                    }),
                  ),
                  meta: [
                    {
                      name: "date",
                      type: "DateTime",
                    },
                    {
                      name: "revenue",
                      type: "Float64",
                    },
                    {
                      name: "orders",
                      type: "UInt64",
                    },
                  ],
                }
              : {
                  data: [],
                  meta: [],
                },
          top_products:
            kpiRes.data.data[0].top_products.length > 0
              ? kpiRes.data.data[0].top_products
              : [],
          sales_by_category:
            kpiRes.data.data[0].revenue_by_category.length > 0
              ? kpiRes.data.data[0].revenue_by_category
              : [],
        }));
      } catch (error) {
        console.error("Error fetching sales data:", error);
      }
    })();
  }, [startDate, endDate, trend, axiosInstance, user?.selectedBranch?.id]);

  return (
    <div className="min-h-screen p-4 md:p-8 bg-[#F9F9FC]">
      <header className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-medium text-[#1D1F2C]">Welcome Back</h1>
          <p className="text-[#667085]">
            Here is your business analysis report
          </p>
        </div>
      </header>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-[330px]">
        <div className="hidden lg:block" />
        <div className="flex justify-end rounded-md pr-2 space-x-1 md:space-x-2 mb-8 bg-white border border-gray-400">
          {["All Data", "12 Months", "30 Days", "7 Days", "24 Hour"].map(
            (period) => (
              <button
                key={period}
                onClick={() => handleDateRange(period)}
                className={`text-secondary text-[12px] md:text-base px-2 md:px-4 py-1 my-1 rounded-md 
                ${
                  datePicker === period
                    ? "bg-[#F0F1F3] text-[#1D1F2C]"
                    : "hover:bg-[#F0F1F3] active:bg-[#F0F1F3] hover:text-[#1D1F2C] active:text-[#1D1F2C]"
                }`}
              >
                {period}
              </button>
            ),
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[
          { title: "Total Order Count", value: form.orders },
          {
            title: "Retention Rate",
            value: `${form.retention_rate}%`,
          },
          {
            title: "Failed Order Count",
            value: form.failed_orders,
          },
          {
            title: "Average Order Value",
            value: `$${form.average_order_value.toFixed(2)}`,
          },
          {
            title: "Total Service Fees",
            value: `$${form.service_fees}`,
          },
          {
            title: "Total Transaction",
            value: `$${form.sales}`,
          },
        ].map((metric) => (
          <InfoCard title={metric.title} info={metric.value} />
        ))}
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-bold mb-4">Top Seller</h3>
          {form.top_products.length > 0 ? (
            <table className="space-y-4 w-full">
              <tbody>
                {form.top_products.map((item) => (
                  <tr key={item[0]} className="text-center">
                    <td className="flex">
                      <div className="w-full flex gap-4">
                        <img
                          src={item[1]}
                          alt={item[0]}
                          className="w-8 h-8 rounded-full"
                        />
                        {item[0]}
                      </div>
                      <div className="flex space-x-5">
                        <span>{item[2]}</span>
                        <span>${item[3]}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center items-center">
              No data available
            </div>
          )}
        </div>
        <div className="bg-white p-6 rounded-lg">
          <h3 className="text-xl font-bold mb-4">Top Categories By Revenue</h3>
          {form.sales_by_category.length > 0 ? (
            <table className="space-y-4 w-full">
              <tbody>
                {form.sales_by_category.map((item) => (
                  <tr key={item[0]} className="text-center">
                    <td>
                      <div className="flex justify-between gap-6">
                        <span>{item[0]}</span>
                        <div className="flex space-x-5">
                          <span>{item[2]}</span>
                          <span>${item[1]}</span>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className="flex justify-center items-center">
              No data available
            </div>
          )}
        </div>
      </div>
      <div className="bg-white p-6 rounded-lg">
        <div className="flex flex-col md:flex-row justify-between md:items-center md:mb-4">
          <h3 className="text-xl font-bold mb-2">Trends</h3>
          <div className="flex justify-around md:justify-end rounded-md px-2 space-x-1 md:space-x-2 mb-8 bg-white border border-gray-400">
            {["Daily", "Weekly", "Monthly", "Yearly"].map((period) => (
              <button
                key={period}
                onClick={() => setTrend(period.toLowerCase())}
                className={`text-secondary text-[12px] md:text-base px-2 md:px-4 py-1 my-1 rounded-md 
                ${
                  trend === period.toLowerCase()
                    ? "bg-[#F0F1F3] text-[#1D1F2C]"
                    : "hover:bg-[#F0F1F3] active:bg-[#F0F1F3] hover:text-[#1D1F2C] active:text-[#1D1F2C]"
                }`}
              >
                {period}
              </button>
            ))}
          </div>
        </div>
        {form.sales_trend.data?.length > 0 ? (
          <AreaChart
            data={form.sales_trend?.data}
            meta={form.sales_trend?.meta}
            index="date"
            colorPalette={colorPalette}
            categories={["orders", "revenue"]}
            stacked={true}
            showLegend={true}
            height="400px"
            padding="20px"
          />
        ) : (
          <div className="flex justify-center items-center h-64">
            No data available
          </div>
        )}
      </div>
    </div>
  );
};

export default Analytics;
