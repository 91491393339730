import { useUser } from "@/contexts/UserContext";
import axios, { AxiosInstance } from "axios";
import { useEffect, useRef, useState } from "react";

export const useAxiosInstance = () => {
  const { user, logout } = useUser();
  const axiosInstanceRef = useRef<AxiosInstance | null>(null);
  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
    });

    axiosInstance.interceptors.request.use(
      (config) => {
        setIsFetching(true);
        if (user && user.access_token) {
          config.headers["Authorization"] = `Bearer ${user.access_token}`;
        }
        return config;
      },
      (error) => {
        setIsFetching(false);
        return Promise.reject(error);
      },
    );

    axiosInstance.interceptors.response.use(
      (response) => {
        setIsFetching(false);
        return response;
      },
      (error) => {
        setIsFetching(false);
        if (error.response && error.response.status === 401) {
          logout();
          window.location.href = "/login";
        }
        return Promise.reject(error);
      },
    );

    axiosInstanceRef.current = axiosInstance;
    setLoading(false);
  }, [user, logout]);

  return [axiosInstanceRef.current, loading, isFetching] as [
    AxiosInstance,
    boolean,
    boolean,
  ];
};
