import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import axiosInstanceNoAuth from "@/utils/axiosConfigNoAuth";
import { Country } from "country-state-city";
import { useUser } from "@/contexts/UserContext";

interface AddEmployeeProps {
  onMenuItemClick: (componentName: string) => void;
}

const AddEmployee = ({ onMenuItemClick }: AddEmployeeProps) => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const { user } = useUser();
  const selectedBranch = user?.selectedBranch;
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [hourlyRate, setHourlyRate] = useState("");
  const [isManager, setIsManager] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState("1");
  const [phoneNumber, setPhoneNumber] = useState("");
  const priorityCountryCodes = ["US", "CN"];
  const allCountries = Country.getAllCountries();

  const filteredCountries = allCountries.filter((country) =>
    priorityCountryCodes.includes(country.isoCode),
  );
  const otherCountries = allCountries.filter(
    (country) => !priorityCountryCodes.includes(country.isoCode),
  );
  const sortedCountries = [...filteredCountries, ...otherCountries];

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const apiUrl = process.env.REACT_APP_REDBIRDPOSBE_EMPLOYEE_INFORMATION ?? "";
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const fullPhoneNumber = `+${selectedCountryCode}${phoneNumber}`;

    const payload = {
      username,
      email,
      password1: password,
      password2: confirmpassword,
      first_name: firstName,
      last_name: lastName,
      group_of_user: "Employee",
      hourly_rate: hourlyRate || "0",
      is_manager: isManager,
      phone: fullPhoneNumber,
      branch: selectedBranch?.id,
    };

    try {
      const response = await axiosInstanceNoAuth.post(apiUrl, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.status === 201) {
        onMenuItemClick("employee");
      } else {
        alert("Failed to add employee. Please check the information.");
      }
    } catch (error) {
      const axiosError = error as any;
      console.error("Error adding employee", axiosError.response?.data);
      const errorMessage =
        axiosError.response?.data?.error ||
        "An error occurred. Please try again later.";
      alert(errorMessage);
    }
  };

  return (
    <div className="container mx-auto px-4 py-2">
      <h1 className="text-xl font-bold">
        {t("back.management.employee.addnewemployee")}
      </h1>
      <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
        <input type="hidden" name="remember" value="true" />
        <div>
          <label
            htmlFor="first_name"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.firstName")}
          </label>
          <input
            id="first_name"
            name="first_name"
            type="text"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            placeholder={t("back.management.employee.enterFirstName")}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </div>
        <div>
          <label
            htmlFor="last_name"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.lastName")}
          </label>
          <input
            id="last_name"
            name="last_name"
            type="text"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            placeholder={t("back.management.employee.enterLastName")}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
        <div>
          <label
            htmlFor="username"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.username")}
          </label>
          <input
            id="username"
            name="username"
            type="text"
            autoComplete="username"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            placeholder={t("back.management.employee.enterUsername")}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.email")}
          </label>
          <input
            id="email"
            name="email"
            type="email"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            placeholder={t("back.management.employee.enterEmail")}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <div>
            <label
              htmlFor="phone_number"
              className="text-sm font-medium text-gray-700 block mb-2"
            >
              {t("back.management.employee.phone")}
            </label>
            <div className="flex">
              <select
                id="country_code"
                name="country_code"
                className="appearance-none rounded-l-md block px-2 py-2 border border-r-0 border-gray-300 bg-white text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm w-20"
                value={selectedCountryCode}
                onChange={(e) => setSelectedCountryCode(e.target.value)}
              >
                {sortedCountries.map((country) => (
                  <option key={country.isoCode} value={country.phonecode}>
                    {country.flag} +{country.phonecode}
                  </option>
                ))}
              </select>
              <input
                id="phone_number"
                name="phone_number"
                type="tel"
                required
                className="appearance-none rounded-r-md block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
                placeholder={t("back.management.employee.enterPhone")}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div>
          <label
            htmlFor="hourly_rate"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.hourlyRate")}
          </label>
          <input
            id="hourly_rate"
            name="hourly_rate"
            type="number"
            step="0.01"
            min="0"
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
            placeholder={t("back.management.employee.enterHourlyRate")}
            value={hourlyRate}
            onChange={(e) => setHourlyRate(e.target.value)}
          />
        </div>
        <div>
          <label
            htmlFor="is_manager"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.isManager")}
          </label>
          <select
            id="is_manager"
            name="is_manager"
            value={isManager ? "true" : "false"}
            onChange={(e) => setIsManager(e.target.value === "true")}
            required
            className="appearance-none rounded-md relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
          >
            <option value="false">{t("back.management.employee.no")}</option>
            <option value="true">{t("back.management.employee.yes")}</option>
          </select>
        </div>
        <div className="relative">
          <label
            htmlFor="password"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.password")}
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <input
              id="password"
              name="password"
              type={showPassword ? "text" : "password"}
              required
              className="appearance-none rounded-l-md block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              placeholder={t("back.management.employee.enterPassword")}
              pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
              title="Password must be at least 8 characters long and include at least one letter and one number."
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button
              onClick={togglePasswordVisibility}
              type="button"
              className="px-3"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
        </div>
        <div className="relative">
          <label
            htmlFor="confirm_password"
            className="text-sm font-medium text-gray-700 block mb-2"
          >
            {t("back.management.employee.confirmPassword")}
          </label>
          <div className="flex items-center border border-gray-300 rounded-md">
            <input
              id="confirm_password"
              name="confirm_password"
              type={showPassword ? "text" : "password"}
              required
              className="appearance-none rounded-l-md block w-full px-3 py-2 text-gray-900 focus:outline-none focus:ring-red-500 focus:border-red-500 sm:text-sm"
              placeholder={t("back.management.employee.enterConfirmPassword")}
              pattern="(?=.*\d)(?=.*[a-zA-Z]).{8,}"
              value={confirmpassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <button
              onClick={togglePasswordVisibility}
              type="button"
              className="px-3"
            >
              <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
            </button>
          </div>
          {passwordError && (
            <p className="text-red-500 text-xs italic">{passwordError}</p>
          )}
        </div>
        <div>
          <button
            type="submit"
            className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            {t("back.management.employee.addEmployee")}
          </button>
        </div>
      </form>
      <div className="flex justify-end mt-4">
        <button
          onClick={() => onMenuItemClick("employee")}
          className="px-4 py-2 bg-gray-500 text-white rounded-md mr-2"
        >
          {t("back.management.menu.product.cancelbutton")}
        </button>
      </div>
    </div>
  );
};

export default AddEmployee;
