import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import axiosInstanceNoAuth from "./axiosConfigNoAuth";
import { v4 as uuidv4 } from "uuid";

const getSessionID = () => {
  let sessionId = localStorage.getItem("session_id");
  if (!sessionId) {
    sessionId = uuidv4();
    localStorage.setItem("session_id", sessionId);
  }
  return sessionId;
};

const useEventTracking = () => {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      const sessionId = getSessionID();
      const geoLocation = () => {
        return new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              resolve(position);
            },
            (error) => {
              console.error("Error getting geolocation", error);
              resolve(null);
            },
          );
        });
      };
      const payload = {
        locale: navigator.language,
        location: await geoLocation(),
        referrer: document.referrer || null,
        pathname: location.pathname,
        href: window.location.href,
        "user-agent": navigator.userAgent,
      };
      const data = {
        session_id: sessionId,
        payload: JSON.stringify(payload),
      };
      try {
        await axiosInstanceNoAuth.post("/tinybird/event-sender/", data);
      } catch (error) {
        console.error("Error sending analytics", error);
      }
    })();
  }, [location]);
};

export default useEventTracking;
