import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./en-US.json";
import zh from "./zh-CN.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: en,
    },
    zh: {
      translation: zh,
    },
  },
});

const locale =
  localStorage.getItem("locale") ?? process.env.REACT_APP_DEFAULT_LANGUAGE;
i18n.changeLanguage(locale);

export default i18n;
